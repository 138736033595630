/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import Layout from "./src/components/layout"
import * as firebase from "firebase/app"
import "firebase/analytics"

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

export const onClientEntry = () => {
  const config = {
    apiKey: "AIzaSyDiNqWJm60whOLBx560cNyJHOZuUOe-NyU",
    authDomain: "slime300-anime.firebaseapp.com",
    databaseURL: "https://slime300-anime.firebaseio.com",
    projectId: "slime300-anime",
    storageBucket: "slime300-anime.appspot.com",
    messagingSenderId: "548723497316",
    appId: "1:548723497316:web:63eece7d0a392e54ce4271",
    measurementId: "G-YDHMXW5LNG",
  }
  firebase.initializeApp(config)
  firebase.analytics()
}
