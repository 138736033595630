import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"

const castList = [
  {
    chara: "アズサ",
    cv: "悠木 碧",
  },
  {
    chara: "ライカ",
    cv: "本渡 楓",
  },
  {
    chara: "ファルファ",
    cv: "千本木彩花",
  },
  {
    chara: "シャルシャ",
    cv: "田中美海",
  },
  {
    chara: "ハルカラ",
    cv: "原田彩楓",
  },
  {
    chara: "ベルゼブブ",
    cv: "沼倉愛美",
  },
  {
    chara: "フラットルテ",
    cv: "和氣あず未",
  },
  {
    chara: "ロザリー",
    cv: "杉山里穂",
  },
  {
    chara: "ペコラ",
    cv: "田村ゆかり",
  },
]
const staffList = [
  {
    position: "原作",
    name: "森田季節",
    subName: "（GAノベル／SBクリエイティブ刊）",
  },
  {
    position: "キャラクター原案",
    name: "紅緒",
  },
  {
    position: "監督",
    name: "木村延景",
  },
  {
    position: "シリーズ構成",
    name: "髙橋龍也",
  },
  {
    position: "キャラクターデザイン",
    name: "後藤圭佑",
  },
  {
    position: "サブキャラクターデザイン",
    name: "本多恵美",
  },
  {
    position: "美術監督",
    name: "内藤 健",
  },
  {
    position: "色彩設計",
    name: "竹澤 聡",
  },
  {
    position: "撮影監督",
    name: "三上颯太",
  },
  {
    position: "音響監督",
    name: "本山 哲",
  },
  {
    position: "音響制作",
    name: "スタジオマウス",
  },
  {
    position: "音楽",
    name: "井内啓二",
  },
  {
    position: "音楽制作",
    name: "日本コロムビア",
  },
  {
    position: "アニメーション制作",
    name: "REVOROOT",
    url: "http://revoroot.co.jp/",
  },
]

const Wrap = styled.div`
  padding: 1.39vw 0;
`
const H3 = styled.h3`
  padding-bottom: 0.5em;
  position: relative;
  ::after {
    content: "";
    background-color: ${(props) => props.theme.colors.light};
    height: 1px;
    width: 1.2em;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6.27vw 3.2vw;
  padding: 7.33vw 0 15.9vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2.71vw 2.92vw;
    padding: 2.64vw 0 4.44vw 0;
  }
  :last-child {
    padding-bottom: 0;
  }
`
const NameSet = styled.div`
  font-size: 1.18rem;
  position: relative;
  padding: 1.2em 0 0.92em 0;
  ::after {
    content: "";
    background-color: ${(props) => props.theme.colors.light};
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  p {
    font-weight: 400;
    word-break: keep-all;
    :first-child {
      color: ${(props) => props.theme.colors.accent};
      font-weight: 300;
      transform: scale(0.71);
      transform-origin: top left;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.29rem;
  }
`
const LinkIcon = styled.img`
  display: block;
  height: 1.3em;
  padding: 0 0.2em;
`
const SubName = styled.p`
  font-size: 0.91rem;
`

const CastStaff = () => {
  return (
    <>
      <SEO title="CAST/STAFF" />
      <h1>CAST / STAFF</h1>
      <h2>キャスト / スタッフ</h2>
      <Wrap>
        <H3>CAST</H3>
        <Content>
          {castList.map((cast, index) => {
            return (
              <NameSet key={index}>
                <p>{cast.chara}</p>
                <p>{cast.cv}</p>
              </NameSet>
            )
          })}
        </Content>
        <H3>STAFF</H3>
        <Content>
          {staffList.map((staff, index) => {
            return (
              <NameSet key={index}>
                {staff.url && (
                  <a href={staff.url} target="_blank" rel="noopener noreferrer">
                    <p>{staff.position}</p>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      {staff.name}
                      <LinkIcon
                        src={require("../images/shared/icon/link.svg")}
                        alt="link"
                      />
                    </p>
                  </a>
                )}
                {!staff.url && (
                  <>
                    <p>{staff.position}</p>
                    <p>{staff.name}</p>
                    {staff.subName && <SubName>{staff.subName}</SubName>}
                  </>
                )}
              </NameSet>
            )
          })}
        </Content>
      </Wrap>
    </>
  )
}
export default CastStaff
