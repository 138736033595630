import React from "react"
// import { GoodsContext } from "../../gatsby-node/goods"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Image from "../components/image"
import { Link } from "gatsby"
import { fadeAnime } from "../components/animation"
import { animated } from "react-spring"
import { ContentfulGoods } from "../../types/graphqlTypes"
import Slider from "react-slick"

const Wrap = styled.div`
  .slick-thumb {
    display: flex !important;
    .slick-active {
      span {
        background-color: ${(props) => props.theme.colors.main};
      }
    }
  }
`
const ContentWrap = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
    > div {
      :first-child {
        width: 44.44vw;
      }
      :last-child {
        flex: 1;
        margin-left: 4.17vw;
      }
    }
  }
`
const H3 = styled.h3`
  border-top: solid 2.5px ${(props) => props.theme.colors.light};
  color: ${(props) => props.theme.colors.font};
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.89rem;
  font-weight: 500;
  line-height: 1.35em;
  margin-top: 7.33vw;
  padding: 8vw 0 5.33vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    border-top: solid 3px ${(props) => props.theme.colors.light};
    font-size: 1.75rem;
    line-height: 1.61em;
    margin-top: 0;
    padding: 2.639vw 0 2.14vw 0;
  }
`
const Desc = styled.p`
  font-weight: 400;
  @media ${(props) => props.theme.breakpoints.pc} {
    line-height: 1.86em;
  }
`
const Content = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.light};
  border-bottom: solid 2.5px ${(props) => props.theme.colors.light};
  margin: 8.8vw 0 13.87vw 0;
  padding: 7vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    border-bottom: solid 3px ${(props) => props.theme.colors.light};
    margin: 2.73vw 0 4.375vw;
    padding: 3.3vw 0;
  }
`
const ImageWrap = styled.div`
  margin: 3em 0;
`
const Thumbnail = styled.img`
  margin-left: -${(props) => props.theme.padding.sp};
  width: 100vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-left: 0;
    width: 100%;
  }
`
const Dots = styled.span`
  background-color: transparent;
  display: block;
  border-radius: 100%;
  border: solid 1px ${(props) => props.theme.colors.main};
  height: 2.67vw;
  min-height: 10px;
  width: 2.67vw;
  min-width: 10px;
  margin: 2.67vw 2.13vw 0 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 0.83vw;
    width: 0.83vw;
    min-height: 12px;
    min-width: 12px;
    margin: 0.69vw 1vw 0 0;
  }
`

const StyledLink = styled(Link)`
  display: block;
  margin: 16vw 0 0 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 8vw 0 0 0;
  }
`
const BackText = styled.p`
  border-bottom: solid 2px ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.accent};
  font-family: "Amiri", sans-serif;
  font-size: 1.64rem;
  letter-spacing: 0.18em;
  line-height: 1em;
  padding: 0.667em 0;
  text-align: center;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
    padding: 1.15em 0;
  }
`
const List = styled.div`
  display: flex;
  margin: 3vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 1.5vw 0;
  }
  a {
    color: ${(props) => props.theme.colors.accent};
    text-decoration: underline;
    :hover {
      cursor: pointer;
    }
  }
  * {
    :first-child {
      margin-left: 4.67vw;
      width: 22vw;
      @media ${(props) => props.theme.breakpoints.pc} {
        margin-left: 1.67vw;
        width: 8.47vw;
      }
    }
    :last-child {
      flex: 1;
      word-break: break-all;
    }
  }
`
const Other = styled.div`
  h1,
  h2 {
    text-align: left;
  }
  h1 {
    font-size: 1.15rem;
    line-height: 1em;
    margin-bottom: 6vw;
    @media ${(props) => props.theme.breakpoints.pc} {
      margin-bottom: 1.46vw;
    }
  }
  h2 {
    color: ${(props) => props.theme.colors.font};
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.35em;
    margin: auto auto 4.67vw auto;
    @media ${(props) => props.theme.breakpoints.pc} {
      line-height: 1.61em;
      margin-bottom: 2.01vw;
    }
  }
  p {
    font-weight: 400;
    @media ${(props) => props.theme.breakpoints.pc} {
      line-height: 1.86em;
    }
  }
  a {
    color: #49b1e0;
    display: block;
    font-size: 1.27rem;
    font-weight: 500;
    margin-top: 1.43em;
    padding-bottom: 1.11em;
    position: relative;
    text-align: center;
    &::before {
      content: "";
      background-color: #64cff0;
      display: block;
      position: absolute;
      bottom: 0;
      height: 2.5px;
      width: 100%;
    }
    &::after {
      content: "";
      display: block;
      background-image: url(${require("../images/shared/tri.svg")});
      background-position: right;
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 1.11em;
      left: 29vw;
      width: 1.33vw;
    }
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 1.43rem;
      ::after {
        left: 11vw;
        width: 0.69vw;
      }
    }
  }
  .gatsby-resp-image-wrapper {
    margin: 8.8vw auto;
    @media ${(props) => props.theme.breakpoints.pc} {
      margin: 2.78vw auto;
    }
  }
`

type GoodsContext = {
  post: ContentfulGoods
}
type Props = {
  pageContext: GoodsContext
}
const Goods = ({ pageContext }: Props) => {
  const content = pageContext.post
  const props = fadeAnime(1000)

  const sliderSettings = {
    customPaging: (i: number) => {
      return (
        <a key={i} style={{ cursor: "pointer" }}>
          <Dots />
        </a>
      )
    },
    // autoplay: true,
    arrows: false,
    dots: true,
    dotsClass: "slick-thumb",
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "ease-in-out",
    autoplaySpeed: 7000,
    beforeChange: () => {
      //scrollbar!.scrollTo(0, 0)
    },
  }
  return (
    <Wrap>
      <SEO title="GOODS" />
      <animated.div style={{ ...props }}>
        <h1>GOODS</h1>
        <h2>グッズ</h2>
        <ContentWrap>
          <div>
            {content.thumbnails && (
              <Slider {...sliderSettings}>
                {content.thumbnails!.map((thumbnail, index) => {
                  return (
                    <img
                      key={index}
                      src={thumbnail?.fluid?.src}
                      alt={thumbnail?.id}
                    />
                  )
                })}
              </Slider>
            )}
            {!content.thumbnails && <Image name="logo" />}
          </div>
          <div>
            <H3>{content.title}</H3>
            {content.description && <Desc>{content.description}</Desc>}
            <Content>
              {content.title && (
                <List>
                  <p>商品名</p>
                  <p>{content.name}</p>
                </List>
              )}
              {content.date && (
                <List>
                  <p>発売日</p>
                  <p>{content.date}</p>
                </List>
              )}
              {content.price && (
                <List>
                  <p>価格</p>
                  <p>{content.price}</p>
                </List>
              )}
              {content.size && (
                <List>
                  <p>サイズ</p>
                  <p>{content.size}</p>
                </List>
              )}
              {content.type && (
                <List>
                  <p>種類数</p>
                  <p>{content.type}</p>
                </List>
              )}
              {content.specification && (
                <List>
                  <p>仕様</p>
                  <p>{content.specification}</p>
                </List>
              )}
              {content.supplier && (
                <List>
                  <p>発売元</p>
                  <p>{content.supplier}</p>
                </List>
              )}
              {content.url && (
                <List>
                  <p>URL</p>
                  <a href={content.url} target="_blank">{content.url}</a>
                </List>
              )}
            </Content>
            {content.other && (
              <Other
                dangerouslySetInnerHTML={{
                  __html: content.other!.childMarkdownRemark!.html!,
                }}
              ></Other>
            )}
          </div>
        </ContentWrap>
        <StyledLink to="/goods/">
          <BackText>BACK TO GOODS</BackText>
        </StyledLink>
      </animated.div>
    </Wrap>
  )
}
export default Goods
