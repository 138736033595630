import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Youtube from "../components/youtube"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Image from "../components/image"
import { SpecialListQuery } from "../../types/graphqlTypes"

const data = [
  {
    name: "PV第1弾",
    url: "https://www.youtube.com/watch?v=1xd5e2-Qb5Q",
    thumbnail: require("../images/visual/pv1-thumb.png"),
  },
  /*
  {
    name: "第一弾PV",
    url: "https://www.youtube.com/watch?v=5vCRyWrBJJo",
    thumbnail: "https://img.youtube.com/vi/5vCRyWrBJJo/0.jpg",
  },
  {
    name: "第一弾PV",
    url: "https://www.youtube.com/watch?v=5vCRyWrBJJo",
    thumbnail: "https://img.youtube.com/vi/5vCRyWrBJJo/0.jpg",
  },
  {
    name: "第一弾PV",
    url: "https://www.youtube.com/watch?v=5vCRyWrBJJo",
    thumbnail: "https://img.youtube.com/vi/5vCRyWrBJJo/0.jpg",
  },
  {
    name: "第一弾PV",
    url: "https://www.youtube.com/watch?v=5vCRyWrBJJo",
    thumbnail: "https://img.youtube.com/vi/5vCRyWrBJJo/0.jpg",
  },
  {
    name: "第一弾PV",
    url: "https://www.youtube.com/watch?v=5vCRyWrBJJo",
    thumbnail: "https://img.youtube.com/vi/5vCRyWrBJJo/0.jpg",
  },
  */
]
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 6.4vw;
  margin-top: 5.33vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: 1fr 1fr;
    gap: 3.33vw 2.22vw;
  }
`
const Content = styled.div``

const ImgWrap = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  :before {
    content: "";
    display: block;
    padding-top: 75%;
  }
`
const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const LogoWrap = styled.div`
  height: 31.25%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`
const Logo = styled(Image)`
  height: 100%;
  opacity: 0.3;
`
const P = styled.p`
  font-size: 0.91rem;
  text-align: center;
  margin-top: 1.2em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`

type Props = {
  data: SpecialListQuery
}
const Special = ({ data }: Props) => {
  return (
    <>
      <SEO title="SPECIAL" />
      <h1>SPECIAL</h1>
      <h2>スペシャル</h2>
      <Wrap>
        {/* <a
          href="https://game.slime300-anime.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Content>
            <ImgWrap>
              <Thumbnail>
                <img
                  alt="game-thumb"
                  src={require("../images/special/game-thumb.png")}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Thumbnail>
            </ImgWrap>
            <P>スライム倒して300年、知らないうちにレベルMAXになってるゲーム</P>
          </Content>
        </a> */}
        {data.allContentfulSpecial.nodes.map((element, index) => {
          return (
            <Link key={element.contentful_id!} to={"/special/" + element.slug}>
              <Content key={index}>
                <ImgWrap>
                  {element.thumbnail ? (
                    <Thumbnail>
                      <img
                        alt={element.title!}
                        src={element.thumbnail!.fluid!.src!}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Thumbnail>
                  ) : (
                    <Thumbnail>
                      <div
                        style={{
                          border: "solid 1px #b2e2f8",
                          height: "100%",
                        }}
                      >
                        <LogoWrap>
                          <Logo name="logo" />
                        </LogoWrap>
                      </div>
                    </Thumbnail>
                  )}
                </ImgWrap>
                <P>{element.title!}</P>
              </Content>
            </Link>
          )
        })}
      </Wrap>
    </>
  )
}
export default Special

export const pageQuery = graphql`
  query SpecialList {
    allContentfulSpecial {
      nodes {
        contentful_id
        title
        thumbnail {
          fluid {
            src
          }
        }
        slug
      }
    }
  }
`
