import React, { useState } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import Modal from "react-modal"

const Content = styled.div`
  cursor: pointer;
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
  &:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`
const PlayerContent = styled(ReactPlayer)`
  background-color: #fff;
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const Thumbnail = styled.div<{ img: string }>`
  background: ${({ img }) => `url("${img}") center center no-repeat`};
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0.1%;
`
const PlayButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 15%;
`
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 999,
  },
}

Modal.setAppElement("#___gatsby")

type Props = {
  thumbnail: string
  url?: string
  className?: string
}
const Youtube = ({ thumbnail, url, className }: Props) => {
  const [isModal, setModal] = useState(false)
  const playButton = require("../images/shared/icon/play.svg")
  const openModal = () => {
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
  }
  return (
    <>
      <Content className={className} onClick={openModal}>
        <Thumbnail img={thumbnail} />
        <PlayButton src={playButton} alt="playButton" />
      </Content>
      <Modal
        isOpen={isModal}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <PlayerContent
          url={url}
          height="100%"
          style={{ pointerEvents: "auto" }}
          width="100%"
        />
      </Modal>
    </>
  )
}
export default Youtube
