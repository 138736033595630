/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Props = {
  title?: string
  description?: string
  meta?: any
}
const SEO = ({ title, description, meta }: Props) => {
  const { site, file } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        file(name: { eq: "ogp" }) {
          publicURL
        }
      }
    `
  )
  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={
        title
          ? title + " | " + site.siteMetadata.title
          : site.siteMetadata.title
      }
      meta={[
        {
          name: `description`,
          content: description ? description : site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title ? title : site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description ? description : site.siteMetadata.description,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${file.publicURL}`, //`${site.siteMetadata.siteUrl}${file.publicURL}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title ? title : site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: description ? description : site.siteMetadata.description,
        },
      ]}
    ></Helmet>
  )
}

export default SEO
