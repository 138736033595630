import { useSpring, config } from "react-spring"

export const fadeAnime = (duration: number) => {
  return (
    useSpring({
      config: { duration: duration },
      from: { opacity: 0 },
      opacity: 1,
    })
  )
}