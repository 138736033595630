import React, { useState, useEffect, createContext } from "react"
import Footer from "./footer"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import "ress/dist/ress.min.css"
import Header from "./header"
import Scrollbar from "smooth-scrollbar"
import Loader from "./loader"
import EventListener from "react-event-listener"

const theme = {
  colors: {
    main: "#129ed9",
    light: "#64cff0",
    accent: "#ff4ca9",
    font: "#2f3d43",
  },
  breakpoints: {
    sp: "screen and (max-width: 767px)",
    pc: "screen and (min-width: 768px)",
  },
  padding: {
    sp: "6.67vw",
    pc: "10vw",
  },
}

const GlobalStyle = createGlobalStyle`
  html,body{
    background-image: url(${require("../images/layout/background.svg")});
    background-repeat: repeat;
    color: ${theme.colors.font};
    font-family: "M PLUS Rounded 1c", sans-serif; 
    font-size: 2.93vw;
    font-weight: 300;
    letter-spacing: 0.1em;
    position: relative;
    z-index: -2;
    @media ${theme.breakpoints.pc}{
      font-size: 0.97vw;
    }
  }
  h1{
    color: #00a0e9;
    font-size: 2.18rem;
    font-family: "Amiri",sans-serif;
    font-weight: 400;
    letter-spacing: 0.18em;
    line-height: 1em;
    text-align: center;
    margin: auto;
    @media ${theme.breakpoints.pc}{
      font-size: 2.57rem;
    }
  }
  h2{
    color: #00a0e9;
    font-size: 1rem;
    font-family: "Noto Serif JP", sans-serif;
    font-weight: 400;
    letter-spacing: 0.18em;
    line-height: 1em;
    text-align: center;
    margin: auto auto 5.15vh auto;
  }
  h3{
    color: #00a0e9;
    font-family: "Amiri", sans-serif;
    font-size: 1.82rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    line-height: 1em;
    @media ${theme.breakpoints.pc} {
    font-size: 2rem;
  }
  }
  small{
    color: #6b7c8c;
    display: block;
    font-size: 0.833rem;
    margin: 3em 0;
  }
  a{
    text-decoration: none;
    :link, :visited, :hover, :active {
      color: ${theme.colors.font}
    }
  }
  li{
    list-style: none;
  }
  button{
    :focus{
      outline: 0;
    }
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .ReactModal__Content{
    background: none !important;
    border: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    padding: 0 !important;
    overflow: hidden !important;
    height: 50.625vw;
    width: 90vw;
    @media ${theme.breakpoints.pc} {
      height: 28.125vw;
      max-height: 80vh;
      width: 50vw;
    }
  }
  .scroll-content{
    backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: transform;
  }
  .scroll-content--stop{
    transform: none !important;
  }
  .menu--open{
    height: -webkit-fill-available;
    overflow: hidden;
  }
`

const Wrap = styled.div<{ isLoad: boolean }>`
  position: relative;
  opacity: ${({ isLoad }) => (isLoad ? 1 : 0)};
`
const ContentWrap = styled.div<{ isMobile: boolean }>`
  overflow: auto;
  height: ${({ isMobile }) => (isMobile ? "auto" : "100vh")};
`
const Content = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Main = styled.main`
  display: block;
  padding: 0 ${theme.padding.sp} 26.7vw ${theme.padding.sp};
  overflow-x: hidden;
  @media ${theme.breakpoints.pc} {
    padding: 3.47vw ${theme.padding.pc} 9.72vw ${theme.padding.pc};
  }
`

export const ScrollAPI = createContext(null as Scrollbar | null)
type Props = {
  className?: string
  children: any
}
const Layout = ({ className, children }: Props) => {
  const [scrollbar, setScrollbar]: any = useState()
  const [isMobile, setMobile] = useState(false)
  const [isTop, setTop] = useState(false)
  const [isLoad, setLoad] = useState(false)
  useEffect(() => {
    setTop(location.pathname === "/" ? true : false)
    const query = document.querySelector("#smooth")
    const ua = window.navigator.userAgent
    if (
      ua.indexOf("iPhone") !== -1 ||
      ua.indexOf("Android") !== -1 ||
      ua.indexOf("iPad") !== -1
    ) {
      setMobile(true)
      setScrollbar(window)
    } else {
      setScrollbar(Scrollbar.init(query as HTMLElement, { damping: 0.1 }))
    }
    setLoad(true)
  }, [])
  useEffect(() => {
    // スクロールバーが定義されていない場合処理しない
    if (scrollbar !== undefined) {
      //　スクロールバーがScrollbar型かどうかを判定
      if (scrollbar.offset) {
        scrollbar?.setMomentum(0, 0)
      }
    }
  }, [children])
  return (
    <ThemeProvider theme={theme}>
      {isTop && <Loader />}
      <Wrap className={className} isLoad={isLoad}>
        <GlobalStyle />
        <Frame />
        <ScrollAPI.Provider value={scrollbar}>
          <ContentWrap id="smooth" isMobile={isMobile}>
            <Content>
              <Header />
              <Main>{children}</Main>
              <Footer />
            </Content>
          </ContentWrap>
        </ScrollAPI.Provider>
      </Wrap>
    </ThemeProvider>
  )
}
export default Layout

const FrameWrap = styled.div`
  transform: translate3d(0, 0, 1px);
  position: fixed;
  pointer-events: none;
  height: -webkit-fill-available;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  @media ${theme.breakpoints.pc} {
    height: 100vh;
    height: -webkit-fill-available;
  }
`
const Corner = styled.img`
  position: absolute;
  top: 1vw;
  right: 1vw;
  bottom: 1vw;
  left: 1vw;
  width: 12vw;
  @media ${theme.breakpoints.pc} {
    top: 0.7vw;
    right: 0.7vw;
    bottom: 0.7vw;
    left: 0.7vw;
    width: 5.2vw;
  }
`
const Horizontal = styled.img`
  position: absolute;
  top: 1vw;
  right: 12vw;
  bottom: 1vw;
  left: 12vw;
  height: 12vw;
  width: calc(100% - 24vw);
  @media ${theme.breakpoints.pc} {
    top: 0.7vw;
    right: 5.2vw;
    bottom: 0.7vw;
    left: 5.2vw;
    height: 5.2vw;
    width: calc(100% - 10.4vw);
  }
`
const Vertical = styled.img`
  position: absolute;
  top: 12vw;
  right: 1vw;
  bottom: 12vw;
  left: 1vw;
  height: calc(100% - 24vw);
  width: 12vw;
  @media ${theme.breakpoints.pc} {
    top: 5.2vw;
    right: 0.7vw;
    bottom: 5.2vw;
    left: 0.7vw;
    height: calc(100% - 10.4vw);
    width: 5.2vw;
  }
`
const TitleText = styled.span`
  font-family: "Amiri", sans-serif;
  font-size: 0.71rem;
  font-weight: 400;
  height: fit-content;
  letter-spacing: 0.2em;
  position: absolute;
  top: 0;
  right: 2.2vw;
  bottom: 0;
  left: auto;
  margin: auto;
  writing-mode: vertical-rl;
  ::first-letter {
    color: ${theme.colors.light};
  }
  @media ${theme.breakpoints.sp} {
    display: none;
  }
`
const TitleTextRev = styled(TitleText)`
  right: auto;
  left: 2.2vw;
  transform: rotate(180deg);
`
const Frame = () => {
  return (
    <>
      <FrameWrap id="frame">
        <Corner
          src={require("../images/layout/corner.svg")}
          style={{ bottom: "auto", right: "auto" }}
          alt="corner"
        />
        <Horizontal
          src={require("../images/layout/horizontal.svg")}
          alt="horizontal"
        />
        <Corner
          src={require("../images/layout/corner.svg")}
          style={{ bottom: "auto", left: "auto", transform: "scale(-1, 1)" }}
          alt="corner"
        />
        <Vertical
          src={require("../images/layout/vertical.svg")}
          style={{ left: "auto", transform: "scale(-1, 1)" }}
          alt="vertical"
        />
        <Corner
          src={require("../images/layout/corner.svg")}
          style={{ top: "auto", left: "auto", transform: "scale(-1, -1)" }}
          alt="corner"
        />
        <Horizontal
          src={require("../images/layout/horizontal.svg")}
          style={{ top: "auto", transform: "scale(1, -1" }}
          alt="horizontal"
        />
        <Corner
          src={require("../images/layout/corner.svg")}
          style={{ top: "auto", right: "auto", transform: "scale(1, -1)" }}
          alt="corner"
        />
        <Vertical
          src={require("../images/layout/vertical.svg")}
          alt="vertical"
        />
        <TitleText id="title__text">
          I’ve Been Killing Slimes for 300 Years and Maxed Out My Level
        </TitleText>
        <TitleTextRev id="title__text--rev">
          I’ve Been Killing Slimes for 300 Years and Maxed Out My Level
        </TitleTextRev>
      </FrameWrap>
    </>
  )
}
