import React, { useState, useRef, useContext } from "react"
import styled from "styled-components"
import Layout, { ScrollAPI } from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { useSpring, animated, useChain, useTrail } from "react-spring"
import CharaImage from "../components/chara-image"

const data = [
  {
    name: "アズサ",
    enName: "AZUSA",
    cv: "悠木 碧",
    thumb: "azusa_thumb",
    thumbWidth: "94.57%",
    image: "azusa",
    imageWidth: "50.67%",
    desc: "高原の魔女。ごく普通の社畜OL（27歳）だったが過労死……。女神の計らいで17歳の容姿を持つ不老不死の魔女として異世界に転生する。ある時、スライムを倒しすぎてレベル99になっていることが発覚。大騒ぎになってしまう。",
  },
  {
    name: "ライカ",
    enName: "LAIKA",
    cv: "本渡 楓",
    thumb: "laika_thumb",
    thumbWidth: "50.48%",
    image: "laika",
    imageWidth: "36.67%",
    desc: "レッドドラゴンの娘。アズサの住むナンテール州で最強のモンスターと呼ばれていたが、アズサに勝負を挑み敗北。少女の姿となって押し掛け弟子入りした。真面目で頑張り屋。",
  },
  {
    name: "ファルファ",
    enName: "FALFA",
    cv: "千本木彩花",
    thumb: "falfa_thumb",
    thumbWidth: "46.96%",
    image: "falfa",
    imageWidth: "23.6%",
    desc: "スライムの精霊（姉）。アズサが倒したスライムの魂が集まって生まれた精霊。同様に実体化した双子の妹のシャルシャに先んじてアズサの元に押し掛け「ママ」と呼んで懐く。素直で朗らかな性格。",
  },
  {
    name: "シャルシャ",
    enName: "SHALSHA",
    cv: "田中美海",
    thumb: "shalsha_thumb",
    thumbWidth: "39.62%",
    image: "shalsha",
    imageWidth: "25.2%",
    desc: "スライムの精霊（妹）。アズサを倒そうと50年かけて修行し乗り込んでくるも破れる。その後ファルファ同様アズサを「母さん」と呼んで懐く。内向的で大人しい性格。",
  },
  {
    name: "ハルカラ",
    enName: "HALKARA",
    cv: "原田彩楓",
    thumb: "halkara_thumb",
    thumbWidth: "76.36%",
    image: "halkara",
    imageWidth: "57.2%",
    desc: "エルフの調薬師。自ら開発した「栄養酒」で大儲けしていたものの、ベルゼブブに狙われアズサの元に助けを求めてやってくる。騒動後そのまま弟子入りすることに。作中一の“やらかし癖”をもつ。",
  },
  {
    name: "ベルゼブブ",
    enName: "BEELZEBUB",
    cv: "沼倉愛美",
    thumb: "beelzebub_thumb",
    thumbWidth: "68.05%",
    image: "beelzebub",
    imageWidth: "59.2%",
    desc: "ハエの王と呼ばれる上級魔族。仕事ができるエリートで、魔族の国で農務大臣を務める。ハルカラの作った「栄養酒」を好み、彼女を探してアズサの元までやって来た。魔族らしく好戦的だが、アズサ同様面倒見が良い。",
  },
  {
    name: "ロザリー",
    enName: "ROSALIE",
    cv: "杉山里穂",
    thumb: "rosalie_thumb",
    thumbWidth: "100%",
    image: "rosalie",
    imageWidth: "54.01%",
    desc: "幽霊の少女。幽霊だからと疎んじず、自分に手を差し伸べてくれたアズサに心酔している。壁は抜けられるが人は触れない。人に憑依することは可能。ヤンキー気質で義理人情や上下関係に敏感。",
  },
  {
    name: "フラットルテ",
    enName: "FLATORTE",
    cv: "和氣あず未",
    thumb: "flatorte_thumb",
    thumbWidth: "54%",
    image: "flatorte",
    imageWidth: "50.6%",
    desc: "ブルードラゴンの娘。ライカの姉の結婚式を襲撃するも、アズサに撃退され服従を誓った。レッドドラゴンであるライカと互いに張り合っている。基本目の前しか見てないので感情と行動が直結しており、あほの子っぽい。",
  },
  {
    name: "ペコラ",
    enName: "PECORA",
    cv: "田村ゆかり",
    thumb: "pecora_thumb",
    thumbWidth: "110%",
    image: "pecora",
    imageWidth: "65%",
    desc: "魔族の国の王。その権力や影響力を使ってアズサや周りの配下を振り回すのが大好きな、小悪魔的気質を備えた女の子。実は「自分より強い者に従属したい」という気質を備えており、叱ってくれたアズサを心酔している。",
  },
  /*
  {
  name: "ファートラ",
  enName: "FATLA",
  cv: "",
  thumb: "fatla_thumb",
  thumbWidth: "0%",
  image: "fatla",
  imageWidth: "0%",
  desc:
    "",
},
  {
  name: "ヴァーニア",
  enName: "VANIA",
  cv: "",
  thumb: "vania_thumb",
  thumbWidth: "0%",
  image: "vania",
  imageWidth: "0%",
  desc:
    "",
},
  {
  name: "ブッスラー",
  enName: "FIGHSLY",
  cv: "",
  thumb: "fighsly_thumb",
  thumbWidth: "0%",
  image: "fighsly",
  imageWidth: "0%",
  desc:
    "",
},
  {
  name: "エノ",
  enName: "ENO",
  cv: "",
  thumb: "eno_thumb",
  thumbWidth: "0%",
  image: "eno",
  imageWidth: "0%",
  desc:
    "",
},
  {
  name: "クク",
  enName: "KUKU",
  cv: "",
  thumb: "kuku_thumb",
  thumbWidth: "0%",
  image: "kuku",
  imageWidth: "0%",
  desc:
    "",
},
*/
]

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.6vw 3.2vw;
  align-items: flex-end;
  justify-content: center;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  text-align: center;
  > p {
    margin: 0 2.8vw;
  }
  > img {
    width: 3.87vw;
    @media ${(props) => props.theme.breakpoints.pc} {
      width: 1.94vw;
    }
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`
const ThumbWrap = styled.div`
  position: relative;
  ::after {
    content: "";
    background-color: #e0f5fc;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 17.33vw;
    width: 100%;
    z-index: -1;
    @media ${(props) => props.theme.breakpoints.pc} {
      height: 6.94vw;
    }
  }
`
const Thumbnail = styled(CharaImage)`
  display: block;
  margin: auto;
`
const BackWrap = styled.button`
  position: relative;
  margin: 10vw 0;
  padding: 0.5em;
  width: 64vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
    margin-top: 0;
    width: 17.78vw;
  }
`
const BackWrapPC = styled(BackWrap)`
  display: none;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: block;
  }
`
const BackText = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-family: "Amiri", sans-serif;
  font-weight: 400;
  letter-spacing: 0.4em;
  position: relative;
  text-align: right;
`
const BackImg = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`
const H3 = styled.h3`
  border-bottom: solid 1px ${(props) => props.theme.colors.light};
  margin: 5.625em 0 2.5em 0;
  padding-bottom: 0.3em;
`
const Character = () => {
  const scrollbar = useContext(ScrollAPI)
  const [viewChara, setChara] = useState(-1)
  const trail = useTrail(data.length, {
    config: { duration: 500 },
    from: { opacity: 0 },
    opacity: 1,
  })
  return (
    <>
      <SEO title="CHARACTER" />
      <h1>CHARACTER</h1>
      <h2>キャラクター</h2>
      {viewChara !== -1 && (
        <>
          <Detail
            index={viewChara}
            element={
              <BackWrapPC
                onClick={() => {
                  setChara(-1)
                  if (scrollbar?.offset) {
                    scrollbar?.setMomentum(0, 0)
                  }
                  scrollbar?.scrollTo(0, 0)
                }}
              >
                <BackText>BACK TO PAGE</BackText>
                <BackImg
                  src={require("../images/shared/backlist.svg")}
                  alt="backlist"
                />
              </BackWrapPC>
            }
          />
          <BackWrap
            onClick={() => {
              setChara(-1)
              if (scrollbar?.offset) {
                scrollbar?.setMomentum(0, 0)
              }
              scrollbar?.scrollTo(0, 0)
            }}
          >
            <BackText>BACK TO PAGE</BackText>
            <BackImg
              src={require("../images/shared/backlist.svg")}
              alt="backlist"
            />
          </BackWrap>
          <H3>CHARACTER</H3>
        </>
      )}
      <Wrap>
        {trail.map(({ opacity }, index) => {
          return (
            <animated.button
              key={index}
              onClick={() => {
                setChara(index)
                if (scrollbar?.offset) {
                  scrollbar?.setMomentum(0, 0)
                }
                scrollbar?.scrollTo(0, 0)
              }}
              style={{ opacity }}
            >
              <ThumbWrap>
                <Thumbnail
                  name={data[index].thumb}
                  style={{ width: data[index].thumbWidth, margin: "auto" }}
                />
              </ThumbWrap>
              <Name>
                <img
                  src={require("../images/shared/text-decoration.svg")}
                  alt="decoration"
                />
                <p>{data[index].name}</p>
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src={require("../images/shared/text-decoration.svg")}
                  alt="decoration-rev"
                />
              </Name>
            </animated.button>
          )
        })}
      </Wrap>
    </>
  )
}
export default Character

const DetailWrap = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
    > div {
      :first-child {
        flex: 1;
      }
    }
  }
`
const NameWrap = styled.div<{ enName: string }>`
  padding: 7vw 0 6vw 0;
  position: relative;
  ::before {
    content: "${({ enName }) => enName}";
    color: #e5f5fd;
    font-family: "Amiri", sans-serif;
    font-size: 5.2rem;
    line-height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    word-break: keep-all;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 11.43rem;
      margin-left: 2.85vw;
    }
  }
  h3 {
    color: ${(props) => props.theme.colors.font};
    font-size: 2.55rem;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 400;
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    h3 {
      font-size: 3.43rem;
    }
    padding: 9vw 0 3.19vw 0;
  }
`
const CV = styled.p`
  font-size: 1.18rem;
  font-weight: 400;
  padding: 2.53vw 0 5.2vw 0;
  position: relative;
  ::after {
    content: "";
    background-color: ${(props) => props.theme.colors.light};
    height: 1px;
    width: 4.27vw;
    position: absolute;
    bottom: 0;
    left: 0;
    @media ${(props) => props.theme.breakpoints.pc} {
      width: 2.22vw;
    }
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
    padding: 1.32vw 0 2.73vw 0;
  }
`
const Desc = styled.p`
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 1.69em;
  margin-bottom: 6vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
    line-height: 2em;
    width: 66%;
  }
`
const ImageWrap = styled.div`
  position: relative;
  width: 100vw;
  margin: 0 -${(props) => props.theme.padding.sp};
  ::after {
    content: "";
    background-color: #e0f5fc;
    height: 74.67vw;
    width: 74.67vw;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    @media ${(props) => props.theme.breakpoints.pc} {
      height: 26.39vw;
      width: 38.89vw;
    }
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 0 -${(props) => props.theme.padding.pc};
    width: 46.8vw;
  }
`
type DetailProps = {
  index: number
  element: any
}
const Detail = ({ index, element }: DetailProps) => {
  const charaData = data[index]

  const nameRef: any = useRef()
  const nameProps = useSpring({
    ref: nameRef,
    config: { duration: 500 },
    from: { opacity: 0 },
    opacity: 1,
    reset: true,
  })
  const imageRef: any = useRef()
  const imageProps = useSpring({
    ref: imageRef,
    config: { duration: 500 },
    from: { opacity: 0 },
    opacity: 1,
    reset: true,
  })
  useChain([nameRef, imageRef], [0, 0.2])

  return (
    <DetailWrap>
      <animated.div style={{ ...nameProps }}>
        <NameWrap enName={charaData.enName}>
          <h3>{charaData.name}</h3>
          <CV>CV：{charaData.cv}</CV>
        </NameWrap>
        <Desc>{charaData.desc}</Desc>
        {element}
      </animated.div>
      <ImageWrap>
        <animated.div
          style={{ ...imageProps, width: charaData.imageWidth, margin: "auto" }}
        >
          <CharaImage name={charaData.image} />
        </animated.div>
      </ImageWrap>
    </DetailWrap>
  )
}
