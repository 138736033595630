import { Link } from "gatsby"
import React from "react"
import Menu from "./menu"
import styled from "styled-components"
import Image from "./image"

const Wrap = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6.67vw;
  width: 86.66vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    height: auto;
    margin: 3.33vw 4.17vw 0 4.17vw;
    width: 91.66vw;
    position: absolute;
    top: 0;
  }
`
const Logo = styled(Link)`
  display: block;
  width: 25.87vw;
  z-index: 1;
  @media ${(props) => props.theme.breakpoints.pc} {
    width: 8.96vw;
    min-width: 129px;
  }
`
const Header = () => {
  return (
    <Wrap>
      <Logo aria-label="index" to={"/"}>
        <Image name="logo" />
      </Logo>
      <Menu />
    </Wrap>
  )
}

export default Header
