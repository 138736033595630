import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Youtube from "../components/youtube"
import { YoutubeVideo } from "../../types/graphqlTypes"
import styled from "styled-components"
import ListLayout from "../components/list-layout"
import { useTrail, animated } from "react-spring"

const nameList = [
  "001",
  "002",
  "003",
  "004",
  "005",
  "006",
  "007",
  "008",
  "009",
  "010",
  "011",
  "012",
  "013",
  "014",
  "015",
  "016",
  "017",
  "018",
  "019",
  "020",
  "021",
  "022",
  "023",
  "024",
  "025",
  "026",
  "027",
  "028",
  "029",
  "030",
  "031",
  "032",
  "033",
  "034",
  "035",
  "036",
  "037",
  "038",
  "039",
  "040",
  "041",
  "042",
  "043",
  "044",
  "045",
  "046",
  "047",
  "048",
  "049",
  "050",
  "051",
  "052",
  "053",
  "054",
  "055",
  "056",
  "057",
  "058",
  "059",
  "060",
  "061",
  "062",
  "063",
  "064",
  "065",
  "066",
  "067",
  "068",
  "069",
  "070",
  "071",
  "072",
  "073",
  "074",
  "075",
  "076",
  "077",
  "078",
  "079",
  "080",
  "081",
  "082",
  "083",
  "084",
  "085",
  "086",
  "087",
  "088",
  "089",
  "090",
  "091",
  "092",
  "093",
  "094",
  "095",
  "096",
  "097",
  "098",
  "099",
  "100",
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "109",
  "110",
  "111",
  "112",
  "113",
  "114",
  "115",
  "116",
  "117",
  "118",
  "119",
  "120",
  "121",
  "122",
  "123",
  "124",
  "125",
  "126",
  "127",
  "128",
  "129",
  "130",
  "131",
  "132",
  "133",
  "134",
  "135",
  "136",
  "137",
  "138",
  "139",
  "140",
  "141",
  "142",
  "143",
  "144",
  "145",
  "146",
  "147",
  "148",
  "149",
  "150",
  "151",
  "152",
  "153",
  "154",
  "155",
  "156",
  "157",
  "158",
  "159",
  "160",
  "161",
  "162",
  "163",
  "164",
  "165",
  "166",
  "167",
  "168",
  "169",
  "170",
  "171",
  "172",
  "173",
  "174",
  "175",
  "176",
  "177",
  "217",
  "218",
  "219",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "235",
  "236",
  "237",
  "238",
  "239",
  "240",
  "241",
  "242",
  "243",
  "244",
  "245",
  "246",
  "247",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "258",
  "259",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "270",
  "271",
  "272",
  "273",
  "274",
  "275",
  "276",
  "277",
  "278",
  "279",
  "280",
  "281",
  "282",
  "283",
  "284",
  "285",
  "286",
  "287",
  "288",
  "289",
  "290",
  "291",
  "292",
  "293",
  "294",
  "295",
  "296",
  "297",
  "298",
  "299",
  "300",
  "アフタートーク",
]
const dateList = [
  "2020.10.09",
  "2020.10.10",
  "2020.10.11",
  "2020.10.12",
  "2020.10.13",
  "2020.10.14",
  "2020.10.15",
  "2020.10.16",
  "2020.10.17",
  "2020.10.18",
  "2020.10.19",
  "2020.10.20",
  "2020.10.21",
  "2020.10.22",
  "2020.10.23",
  "2020.10.24",
  "2020.10.25",
  "2020.10.26",
  "2020.10.27",
  "2020.10.28",
  "2020.10.29",
  "2020.10.30",
  "2020.10.31",
  "2020.11.01",
  "2020.11.02",
  "2020.11.03",
  "2020.11.04",
  "2020.11.05",
  "2020.11.06",
  "2020.11.07",
  "2020.11.08",
  "2020.11.09",
  "2020.11.10",
  "2020.11.11",
  "2020.11.12",
  "2020.11.13",
  "2020.11.14",
  "2020.11.15",
  "2020.11.16",
  "2020.11.17",
  "2020.11.18",
  "2020.11.19",
  "2020.11.20",
  "2020.11.21",
  "2020.11.22",
  "2020.11.23",
  "2020.11.24",
  "2020.11.25",
  "2020.11.26",
  "2020.11.27",
  "2020.11.28",
  "2020.11.29",
  "2020.11.30",
  "2020.12.01",
  "2020.12.02",
  "2020.12.03",
  "2020.12.04",
  "2020.12.05",
  "2020.12.06",
  "2020.12.07",
  "2020.12.08",
  "2020.12.09",
  "2020.12.10",
  "2020.12.11",
  "2020.12.12",
  "2020.12.13",
  "2020.12.14",
  "2020.12.15",
  "2020.12.16",
  "2020.12.17",
  "2020.12.18",
  "2020.12.19",
  "2020.12.20",
  "2020.12.21",
  "2020.12.22",
  "2020.12.23",
  "2020.12.24",
  "2020.12.25",
  "2020.12.26",
  "2020.12.27",
  "2020.12.28",
  "2020.12.29",
  "2020.12.30",
  "2020.12.31",
  "2021.01.01",
  "2021.01.02",
  "2021.01.03",
  "2021.01.04",
  "2021.01.05",
  "2021.01.06",
  "2021.01.07",
  "2021.01.08",
  "2021.01.09",
  "2021.01.10",
  "2021.01.11",
  "2021.01.12",
  "2021.01.13",
  "2021.01.14",
  "2021.01.15",
  "2021.01.16",
  "2021.01.17",
  "2021.01.18",
  "2021.01.19",
  "2021.01.20",
  "2021.01.21",
  "2021.01.22",
  "2021.01.23",
  "2021.01.24",
  "2021.01.25",
  "2021.01.26",
  "2021.01.27",
  "2021.01.28",
  "2021.01.29",
  "2021.01.30",
  "2021.01.31",
  "2021.02.01",
  "2021.02.02",
  "2021.02.03",
  "2021.02.04",
  "2021.02.05",
  "2021.02.06",
  "2021.02.07",
  "2021.02.08",
  "2021.02.09",
  "2021.02.10",
  "2021.02.11",
  "2021.02.12",
  "2021.02.13",
  "2021.02.14",
  "2021.02.15",
  "2021.02.16",
  "2021.02.17",
  "2021.02.18",
  "2021.02.19",
  "2021.02.20",
  "2021.02.21",
  "2021.02.22",
  "2021.02.23",
  "2021.02.24",
  "2021.02.25",
  "2021.02.26",
  "2021.02.27",
  "2021.02.28",
  "2021.03.01",
  "2021.03.02",
  "2021.03.03",
  "2021.03.04",
  "2021.03.05",
  "2021.03.06",
  "2021.03.07",
  "2021.03.08",
  "2021.03.09",
  "2021.03.10",
  "2021.03.11",
  "2021.03.12",
  "2021.03.13",
  "2021.03.14",
  "2021.03.15",
  "2021.03.16",
  "2021.03.17",
  "2021.03.18",
  "2021.03.19",
  "2021.03.20",
  "2021.03.21",
  "2021.03.22",
  "2021.03.23",
  "2021.03.24",
  "2021.03.25",
  "2021.03.26",
  "2021.03.27",
  "2021.03.28",
  "2021.03.29",
  "2021.03.30",
  "2021.03.31",
  "2021.04.01",
  "2021.04.02",
  "2021.04.03",
  "2021.04.04",
  "2021.04.05",
  "2021.04.06",
  "2021.04.07",
  "2021.04.08",
  "2021.04.09",
  "2021.04.10",
  "2021.04.11",
  "2021.04.12",
  "2021.04.13",
  "2021.04.14",
  "2021.04.15",
  "2021.04.16",
  "2021.04.17",
  "2021.04.18",
  "2021.04.19",
  "2021.04.20",
  "2021.04.21",
  "2021.04.22",
  "2021.04.23",
  "2021.04.24",
  "2021.04.25",
  "2021.04.26",
  "2021.04.27",
  "2021.04.28",
  "2021.04.29",
  "2021.04.30",
  "2021.05.01",
  "2021.05.02",
  "2021.05.03",
  "2021.05.04",
  "2021.05.05",
  "2021.05.06",
  "2021.05.07",
  "2021.05.08",
  "2021.05.09",
  "2021.05.10",
  "2021.05.11",
  "2021.05.12",
  "2021.05.13",
  "2021.05.14",
  "2021.05.15",
  "2021.05.16",
  "2021.05.17",
  "2021.05.18",
  "2021.05.19",
  "2021.05.20",
  "2021.05.21",
  "2021.05.22",
  "2021.05.23",
  "2021.05.24",
  "2021.05.25",
  "2021.05.26",
  "2021.05.27",
  "2021.05.28",
  "2021.05.29",
  "2021.05.30",
  "2021.05.31",
  "2021.06.01",
  "2021.06.02",
  "2021.06.03",
  "2021.06.04",
  "2021.06.05",
  "2021.06.06",
  "2021.06.07",
  "2021.06.08",
  "2021.06.09",
  "2021.06.10",
  "2021.06.11",
  "2021.06.12",
  "2021.06.13",
  "2021.06.14",
  "2021.06.15",
  "2021.06.16",
  "2021.06.17",
  "2021.06.18",
  "2021.06.19",
  "2021.06.20",
  "2021.06.21",
  "2021.06.22",
  "2021.06.23",
  "2021.06.24",
  "2021.06.25",
  "2021.06.26",
]
const RadioMessageWrap = styled.div`
  background-color: rgba(178, 226, 248, 0.2);
  margin: -14.67vw 0 14.67vw -${(props) => props.theme.padding.sp};
  width: 100vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: -5.56vw 0 5.56vw -${(props) => props.theme.padding.pc};
  }
`
const RadioMessage = styled.p`
  color: ${(props) => props.theme.colors.main};
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  padding: 1.86em ${(props) => props.theme.padding.sp};
  @media ${(props) => props.theme.breakpoints.pc} {
    padding: 1.5em ${(props) => props.theme.padding.pc};
    ::before,
    ::after {
      content: "";
      background-color: ${(props) => props.theme.colors.accent};
      display: block;
      height: 1px;
      margin: 0 1em;
      width: 2.85vw;
    }
  }
`
const Br = styled.br`
  @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  }
`
const HeadWrap = styled.div`
  display: block;
  margin-bottom: 8.27vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.89vw;
    > :last-child {
      width: 51vw;
    }
  }
`
const LatestWrap = styled.div`
  border-top: solid 3px ${(props) => props.theme.colors.main};
  border-bottom: solid 1px ${(props) => props.theme.colors.main};
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 8.53vw 0;
  margin-bottom: 8.53vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    margin-right: 5.56vw;
    margin-bottom: 0;
    padding: 6.04vw 2.7vw;
  }
`
const LInfo = styled.div`
  position: relative;
  padding-right: 4.13vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-bottom: 2.5vw;
    padding: 0 0 1.67vw 0;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.main};
    @media ${(props) => props.theme.breakpoints.pc} {
      height: 1px;
      width: 2.22vw;
      top: auto;
      left: 0;
      right: auto;
    }
  }
`
const LDate = styled.p`
  font-size: 1.09rem;
  font-weight: 300;
  letter-spacing: 0.075em;
`
const LIndex = styled.p`
  font-size: 1.67rem;
  font-weight: 400;
  letter-spacing: 0.075em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2.28rem;
  }
`
const LIndexSpan = styled.span`
  font-size: 3.64rem;
  line-height: 1em;
  margin: 0 1px;
`
const LTitle = styled.p`
  font-size: 1.18rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.6em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
  }
`
const ListWrap = styled.div`
  display: grid;
  gap: 4.13vw 3.2vw;
  grid-template-columns: 1fr 1fr;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: repeat(4, 1fr);
    gap: 3.82vw 2.92vw;
  }
`
const ChildWrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const ChildIndex = styled.p`
  font-size: 0.91rem;
  font-weight: 400;
  line-height: 2.2em;
  letter-spacing: 0.1em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`
const ChildDate = styled(ChildIndex)`
  font-size: 0.91rem;
  letter-spacing: 0.075em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`
const RadioMovie = styled(Youtube)`
  border: none;
  box-shadow: none;
`

interface ExYoutubeVideo extends YoutubeVideo {
  tag: string
}
type ExRadioQuery = {
  __typename?: "Query" | undefined
} & {
  allYoutubeVideo: {
    __typename?: "YoutubeVideoConnection" | undefined
  } & {
    nodes: ({
      __typename?: "YoutubeVideo" | undefined
    } & Pick<
      ExYoutubeVideo,
      "id" | "thumbnail" | "url" | "publishedAt" | "tag"
    >)[]
  }
}
type Props = {
  data: ExRadioQuery
}
const RadioPage = ({ data }: Props) => {
  const [viewTag, setTag] = useState("")
  const [viewCount, setViewCount] = useState(8)
  let pageCount = 0

  const filterData = data.allYoutubeVideo.nodes.filter((node) => {
    if (viewTag === "") {
      return node.tag
    }
    return node.tag === viewTag
  })

  const trail = useTrail(filterData.length, {
    config: { duration: 750 },
    from: { opacity: 0 },
    opacity: 1,
  })

  const lastIndex = data.allYoutubeVideo.nodes.length - 1
  useEffect(() => {}, [])
  const pushTag = () => {
    let count = 0
    let pageIndex = 0
    data.allYoutubeVideo.nodes.map((element, index) => {
      if (index <= 149) {
        if (count === 50) {
          count = 0
          pageIndex++
        }
        element.tag = `${pageIndex * 50 + 1}-${pageIndex * 50 + 50}`
        count++
      }
      if (index > 149 && index <= 176) {
        element.tag = `151-177`
      }
      if (index > 176 && index <= 210) {
        element.tag = `217-250`
      }
      if (index > 210 && index <= 260) {
        element.tag = "251-300"
      }
    })
  }
  pushTag()
  return (
    <>
      <SEO title="RADIO" />
      <h1>RADIO</h1>
      <h2>ラジオ</h2>
      <ListLayout
        nodes={data.allYoutubeVideo.nodes}
        tag={viewTag}
        setTag={(tag: string) => setTag(tag)}
        count={viewCount}
        setCount={(count: number) => setViewCount(count)}
        length={filterData.length}
      >
        <RadioMessageWrap>
          <RadioMessage>
            本渡 楓(ライカ役)、千本木彩花(ファルファ役)による
            <Br />
            1分ラジオ、毎日18時ごろに配信中！
          </RadioMessage>
        </RadioMessageWrap>
        <HeadWrap>
          <LatestWrap>
            <LInfo>
              <LDate>{dateList[lastIndex]}</LDate>
              <LIndex>
                <LIndexSpan>{nameList[lastIndex]}</LIndexSpan>
              </LIndex>
            </LInfo>
            <LTitle>
              スライム倒して300分、
              <br />
              知らないうちに
              <br />
              300分話してました
            </LTitle>
          </LatestWrap>
          <RadioMovie
            url={data.allYoutubeVideo.nodes[lastIndex].url!}
            thumbnail={data.allYoutubeVideo.nodes[lastIndex].thumbnail!}
          />
        </HeadWrap>
        <ListWrap>
          {trail.map(({ opacity }, index) => {
            pageCount += 1
            const no =
              data.allYoutubeVideo.nodes.findIndex(
                (item) => item.id === filterData[index].id
              ) + 1
            if (pageCount > viewCount) {
              return
            }
            return (
              <animated.div key={filterData[index].id} style={{ opacity }}>
                <RadioMovie
                  url={filterData[index].url!}
                  thumbnail={filterData[index].thumbnail!}
                />
                <ChildWrap>
                  <ChildIndex>{nameList[no - 1]}分め</ChildIndex>
                  <ChildDate>{dateList[no - 1]}</ChildDate>
                </ChildWrap>
              </animated.div>
            )
          })}
        </ListWrap>
      </ListLayout>
    </>
  )
}
export default RadioPage

export const pageQuery = graphql`
  query Radio {
    allYoutubeVideo(
      sort: { fields: title, order: ASC }
      filter: { thumbnail: { ne: null } }
    ) {
      nodes {
        id
        thumbnail
        url
        publishedAt
      }
    }
  }
`
