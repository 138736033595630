// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bluray-cd-tsx": () => import("./../../../src/pages/bluray-cd.tsx" /* webpackChunkName: "component---src-pages-bluray-cd-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-cast-staff-tsx": () => import("./../../../src/pages/cast-staff.tsx" /* webpackChunkName: "component---src-pages-cast-staff-tsx" */),
  "component---src-pages-character-tsx": () => import("./../../../src/pages/character.tsx" /* webpackChunkName: "component---src-pages-character-tsx" */),
  "component---src-pages-goods-tsx": () => import("./../../../src/pages/goods.tsx" /* webpackChunkName: "component---src-pages-goods-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movie-tsx": () => import("./../../../src/pages/movie.tsx" /* webpackChunkName: "component---src-pages-movie-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-onair-tsx": () => import("./../../../src/pages/onair.tsx" /* webpackChunkName: "component---src-pages-onair-tsx" */),
  "component---src-pages-radio-tsx": () => import("./../../../src/pages/radio.tsx" /* webpackChunkName: "component---src-pages-radio-tsx" */),
  "component---src-pages-special-tsx": () => import("./../../../src/pages/special.tsx" /* webpackChunkName: "component---src-pages-special-tsx" */),
  "component---src-pages-story-tsx": () => import("./../../../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */),
  "component---src-templates-goods-tsx": () => import("./../../../src/templates/goods.tsx" /* webpackChunkName: "component---src-templates-goods-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-special-tsx": () => import("./../../../src/templates/special.tsx" /* webpackChunkName: "component---src-templates-special-tsx" */)
}

