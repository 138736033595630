import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import Youtube from "../components/youtube"
import Img from "gatsby-image"
import Slider from "react-slick"
import { animated, useSpring } from "react-spring"

/////////////////////////////////////////////////////////////////////////////////
type ImagesProps = {
  name: string
  style?: object
  imgStyle?: object
}
const Images = ({ name, style, imgStyle }: ImagesProps) => {
  const data = useStaticQuery(graphql`
    {
      op01: file(relativePath: { eq: "bdcd/op01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      op02: file(relativePath: { eq: "bdcd/op02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ed01: file(relativePath: { eq: "bdcd/ed01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ed02: file(relativePath: { eq: "bdcd/ed02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bd_box01: file(relativePath: { eq: "bdcd/bd_box01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bd_box02: file(relativePath: { eq: "bdcd/bd_box02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bd_deji01: file(relativePath: { eq: "bdcd/bd_deji01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bd_deji02: file(relativePath: { eq: "bdcd/bd_deji02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      reserve: file(relativePath: { eq: "bdcd/reserve.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      animate01: file(relativePath: { eq: "bdcd/animate01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      animate02: file(relativePath: { eq: "bdcd/animate02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      gamers01: file(relativePath: { eq: "bdcd/gamers01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gamers02: file(relativePath: { eq: "bdcd/gamers02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      toranoana: file(relativePath: { eq: "bdcd/toranoana.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sofmap: file(relativePath: { eq: "bdcd/sofmap.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      amiami: file(relativePath: { eq: "bdcd/amiami.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rakuten: file(relativePath: { eq: "bdcd/rakuten.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      amazon: file(relativePath: { eq: "bdcd/amazon.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cms01: file(relativePath: { eq: "bdcd/cms01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cms02: file(relativePath: { eq: "bdcd/cms02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nowprinting: file(relativePath: { eq: "shared/nowprinting.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "shared/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      animate01: file(relativePath: { eq: "bdcd/animate01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      loading="lazy"
      style={style}
      imgStyle={imgStyle}
    />
  )
}

/////////////////////////////////////////////////////////////////////////////////
const SpecialWrap = styled.div`
  background-color: #edf9ff;
  padding: 8vw 6.67vw;
  margin-top: 8vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    padding: 3.33vw 4.17vw;
    margin-top: 1.67vw;
  }
`
const SpecialTitle = styled.p`
  font-size: 1.45rem;
  font-weight: 500;
  margin-bottom: 1em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.29rem;
  }
`
const SpecialText = styled.p`
  font-weight: 400;
  margin: 1.36em auto;
`
const SpecialImgWrap = styled.div`
  margin: 1.36em auto;
`
const SpecialLink = styled.a`
  color: #49b1e0;
  display: block;
  font-size: 1.27rem;
  font-weight: 500;
  margin-top: 1.43em;
  padding-bottom: 1.11em;
  position: relative;
  text-align: center;
  &::before {
    content: "";
    background-color: #64cff0;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2.5px;
    width: 100%;
  }
  &::after {
    content: "";
    display: block;
    background-image: url(${require("../images/shared/tri.svg")});
    background-position: right;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 1.11em;
    left: 29vw;
    width: 1.33vw;
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
    ::after {
      left: 11vw;
      width: 0.69vw;
    }
  }
`

type SpecialProps = {
  title: string
  desc: string
  imgList: JSX.Element[]
  other?: JSX.Element
  link?: string
}
const Special = ({ title, desc, imgList, other, link }: SpecialProps) => {
  return (
    <SpecialWrap>
      <SpecialTitle>{title}</SpecialTitle>
      <SpecialText>{desc}</SpecialText>
      {imgList.map((img: JSX.Element, index) => {
        return <SpecialImgWrap key={index}>{img}</SpecialImgWrap>
      })}
      {other && <div>{other}</div>}
      {link && <SpecialLink href={link}>購入はこちら</SpecialLink>}
    </SpecialWrap>
  )
}
/////////////////////////////////////////////////////////////////////////////////

const Nav = styled.div`
  display: flex;
  justify-content: center;
`
const NavButton = styled.button<{ selected: boolean }>`
  display: block;
  border-bottom: solid 1px
    ${({ selected }) =>
      selected
        ? (props) => props.theme.colors.accent
        : (props) => props.theme.colors.light};
  color: ${({ selected }) =>
    selected
      ? (props) => props.theme.colors.accent
      : (props) => props.theme.colors.main};
  font-family: "Amiri", sans-serif;
  font-size: 1.45rem;
  letter-spacing: 0.1em;
  height: 6.93vw;
  margin: auto 3.2vw;
  position: relative;
  width: 32vw;
  ::before {
    content: "";
    background-color: ${({ selected }) =>
      selected ? (props) => props.theme.colors.accent : "transparent"};
    display: block;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2.28rem;
    height: 3.61vw;
    width: 16.67vw;
  }
`

const Content = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
    padding-top: 6.18vw;
  }
`
const Outline = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-right: 5.56vw;
    width: 20.139vw;
  }
`
const OutlineTitle = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.18rem;
  font-weight: 500;
  margin: 4vw auto;
  text-align: center;
  ::before,
  ::after {
    content: "";
    display: block;
    background-image: url(${require("../images/shared/text-decoration.svg")});
    background-position: left;
    height: 1em;
    margin: auto 2.27vw;
    width: 5.87vw;
  }
  ::after {
    transform: rotate(180deg);
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 1.39vw auto;
    ::before,
    ::after {
      margin: auto 0.764vw;
      width: 2.01vw;
    }
  }
`
const OutlineSubTitle = styled.p`
  font-size: 1.09rem;
  font-weight: 500;
  text-align: center;
  margin-top: 8.53vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
    margin-top: 0;
  }
`
const OutlineNav = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5.47vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-bottom: 1.6vw;
  }
`
const OutlineNavButton = styled.button<{ selected: boolean }>`
  border-bottom: solid 1px
    ${({ selected }) =>
      selected ? "transparent" : (props) => props.theme.colors.light};
  color: ${({ selected }) =>
    selected
      ? (props) => props.theme.colors.main
      : (props) => props.theme.colors.font};
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.45rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  height: 10.4vw;
  margin: auto 3.2vw;
  position: relative;
  width: 32vw;
  ::before {
    content: "";
    background-color: ${({ selected }) =>
      selected ? (props) => props.theme.colors.main : "transparent"};
    display: block;
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  ::after {
    content: "";
    display: block;
    background-image: url(${require("../images/shared/tri.svg")});
    background-position: right;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 15vw;
    width: 1.33vw;
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 3.61vw;
    margin: auto 0.486vw;
    width: 9.58vw;
    ::after {
      left: 5vw;
      width: 0.417vw;
    }
  }
`
const OutlineProduct = styled.p<{ accent?: boolean }>`
  color: ${({ accent }) =>
    accent ? (props) => props.theme.colors.accent : "initial"};
  text-align: center;
  font-weight: 400;
  @media ${(props) => props.theme.breakpoints.pc} {
    line-height: 1.71em;
  }
`
const Detail = styled.section`
  border-bottom: solid 1px ${(props) => props.theme.colors.light};
  overflow-x: hidden;
  padding: 9.6vw 0 10.67vw 0;
  .slick-thumb {
    display: flex !important;
    margin: 3.73vw auto 6.4vw auto;
    .slick-active {
      border: solid 2px ${(props) => props.theme.colors.main};
    }
    li {
      margin-right: 2vw;
    }
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    padding-top: 0;
    flex: 1;
    .slick-thumb {
      margin: 1.11vw auto 3.82vw auto;
    }
  }
`
const DetailH1 = styled.h1`
  color: inherit;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.45rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.44em;
  margin: 3.27em auto 1.11em auto;
  :first-child {
    margin-top: 0;
  }
`
const DetailH2 = styled.h2`
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.45rem;
  font-weight: 500;
  margin: 2.25em auto 0.83em 0;
  padding-bottom: 0.5em;
  position: relative;
  width: fit-content;
  ::before,
  ::after {
    content: "";
    background-color: ${(props) => props.theme.colors.main};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
  }
  ::after {
    opacity: 0.2;
    width: 100vw;
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.57rem;
  }
`
const DetailOl = styled.ol``
const DetailLi = styled.li`
  display: list-item;
  font-weight: 400;
  list-style: decimal;
  list-style-position: inside;
  ::marker {
    color: ${(props) => props.theme.colors.main};
  }
`
const DetailMainImage = styled.div`
  background-color: #fff;
  border: solid 1px ${(props) => props.theme.colors.light};
`
const DetailSubImage = styled.div`
  background-color: #fff;
  border: solid 1px ${(props) => props.theme.colors.light};
  height: 19.33vw;
  margin: auto;
  width: 24vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 6.74vw;
    width: 8.33vw;
  }
`
const DetailSprit = styled.div``

const Br = styled.br`
  display: block;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  }
`

/////////////////////////////////////////////////////////////////////////////////

const sliderSettings01 = {
  customPaging: (i: number) => {
    return (
      <a style={{ cursor: "pointer" }}>
        <DetailSubImage>
          <Images
            name={bdImageDataList01[i].name}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </DetailSubImage>
      </a>
    )
  },
  autoplay: false,
  arrows: false,
  dots: true,
  dotsClass: "slick-thumb",
  infinite: true,
  adaptiveHeight: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  easing: "ease-in-out",
  beforeChange: () => {
    //scrollbar!.scrollTo(0, 0)
  },
}
const sliderSettings02 = {
  customPaging: (i: number) => {
    return (
      <a style={{ cursor: "pointer" }}>
        <DetailSubImage>
          <Images
            name={bdImageDataList02[i].name}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </DetailSubImage>
      </a>
    )
  },
  autoplay: false,
  arrows: false,
  dots: true,
  dotsClass: "slick-thumb",
  infinite: true,
  adaptiveHeight: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  easing: "ease-in-out",
  beforeChange: () => {
    //scrollbar!.scrollTo(0, 0)
  },
}

const bdImageDataList01 = [
  {
    index: 1,
    name: "bd_box01",
  },
  {
    index: 2,
    name: "bd_deji01",
  },
]
const bdImageDataList02 = [
  {
    index: 1,
    name: "bd_box02",
  },
  {
    index: 1,
    name: "bd_deji02",
  },
]

const bItem = [
  {
    name: "上巻",
    outline: (
      <>
        <OutlineProduct>発売日：2021年6月30日</OutlineProduct>
        <OutlineProduct>品番：COXC-1218</OutlineProduct>
        <OutlineProduct>収録話数：1話〜6話</OutlineProduct>
        <OutlineProduct>価格：19,800円（税込）</OutlineProduct>
      </>
    ),
    detail: (
      <>
        <Slider {...sliderSettings01}>
          {bdImageDataList01.map((image, index) => {
            return <Images key={index} name={image.name} />
          })}
        </Slider>
        <DetailH2>仕様</DetailH2>
        <DetailOl>
          <DetailLi>キャラクター原案『紅緒』描き下ろし三⽅背BOX</DetailLi>
          <DetailLi>
            キャラクターデザイン『後藤圭佑』描き下ろしデジパック
          </DetailLi>
        </DetailOl>
        <DetailH2>封入特典</DetailH2>
        <DetailOl>
          <DetailLi>スペシャルブックレット</DetailLi>
          <DetailLi>ノンテロップOP/ED</DetailLi>
          <DetailLi>WEB予告</DetailLi>
        </DetailOl>
        <DetailH2>早期予約特典</DetailH2>
        <Special
          title="Blu-ray上巻を下記対象店舗にてご予約いただくと先着でキャラクター原案：紅緒 描き下ろしミニ色紙を商品お受け取り時にプレゼント！"
          desc="キャンペーン期間：2021年4月10日（土）より　※特典無くなり次第終了"
          imgList={[<Images name="reserve" />]}
          other={
            <>
              <SpecialTitle>対象店舗</SpecialTitle>
              <p>・全国アニメイト（通販含む）</p>
              <p>・ゲーマーズ全店（オンラインショップ含む）</p>
              <p>・とらのあな全店(一部店舗除く)・通信販売</p>
              <p>・ソフマップ(映像ソフト取り扱い店のみ、ドットコム含む)</p>
              <p>・Amazon.co.jp</p>
              <p>・コロムビアミュージックショップ</p>
              <p>・楽天ブックス</p>
              <p>・メロンブックス（通販含む）</p>
              <p>・ネオ・ウィング</p>
              <p>・ぐるぐる王国</p>
              <p>・HMV店舗・HMV＆BOOKS online</p>
              <p>・WonderGOO／新星堂（一部店舗を除く）</p>
              <p>・あみあみ</p>
            </>
          }
        />
        <DetailH2>店舗別特典特典</DetailH2>
        <Special
          title="全国アニメイト（通販含む）"
          desc="全巻購入特典：B2マルチクロス&アニメイト描き起こしアクリルキーホルダー2個セット （アズサ&ライカ）"
          imgList={[<Images name="animate01" />, <Images name="animate02" />]}
        />
        <Special
          title="ゲーマーズ全店（オンラインショップ含む）"
          desc="全巻購入特典：B2タペストリー"
          imgList={[<Images name="gamers01" />]}
          other={
            <>
              <SpecialTitle>ゲーマーズ限定版発売（上巻のみ）</SpecialTitle>
              <p>セット内容：丸型缶バッジ6個セット</p>
              <p>限定版価格：￥21,780（税込）</p>
              <Images name="gamers02" />
            </>
          }
        />
        <Special
          title="とらのあな店舗(一部店舗除く)・通信販売"
          desc="全巻購入特典：アニメ描き下ろしB2タペストリー"
          imgList={[<Images name="toranoana" />]}
        />
        <Special
          title="ソフマップ・アニメガ"
          desc="全巻購入特典：アニメ描き下ろしB1タペストリー"
          imgList={[<Images name="sofmap" />]}
        />
        <Special
          title="あみあみ"
          desc="全巻購入特典：アクリルクロック"
          imgList={[<Images name="amiami" />]}
        />
        <Special
          title="楽天ブックス"
          desc="全巻購入特典：キャラファインボード"
          imgList={[<Images name="rakuten" />]}
        />
        <Special
          title="アマゾン"
          desc="全巻購入特典：B2布ポスター"
          imgList={[<Images name="amazon" />]}
        />
        <Special
          title="コロムビアミュージックショップ"
          desc="各巻購入特典：A4クリアファイル"
          imgList={[<Images name="cms01" />]}
        />
      </>
    ),
  },
  {
    name: "下巻",
    outline: (
      <>
        <OutlineProduct>発売日：2021年7月28日</OutlineProduct>
        <OutlineProduct>品番：COXC-1219</OutlineProduct>
        <OutlineProduct>収録話数：7話〜12話</OutlineProduct>
        <OutlineProduct>価格：19,800円（税込）</OutlineProduct>
      </>
    ),
    detail: (
      <>
        <Slider {...sliderSettings02}>
          {bdImageDataList02.map((image, index) => {
            return <Images key={index} name={image.name} />
          })}
        </Slider>
        <DetailH2>仕様</DetailH2>
        <DetailOl>
          <DetailLi>キャラクター原案『紅緒』描き下ろし三⽅背BOX</DetailLi>
          <DetailLi>
            キャラクターデザイン『後藤圭佑』描き下ろしデジパック
          </DetailLi>
        </DetailOl>
        <DetailH2>封入特典</DetailH2>
        <DetailOl>
          <DetailLi>スペシャルブックレット</DetailLi>
          <DetailLi>オリジナルサウンドトラックCD</DetailLi>
          <DetailLi>PV集</DetailLi>
          <DetailLi>WEB予告</DetailLi>
        </DetailOl>
        <DetailH2>店舗別特典特典</DetailH2>
        <Special
          title="全国アニメイト（通販含む）"
          desc="全巻購入特典：B2マルチクロス&アニメイト描き起こしアクリルキーホルダー2個セット （アズサ&ライカ）"
          imgList={[<Images name="animate01" />, <Images name="animate02" />]}
        />
        <Special
          title="ゲーマーズ全店（オンラインショップ含む）"
          desc="全巻購入特典：B2タペストリー"
          imgList={[<Images name="gamers01" />]}
        />
        <Special
          title="とらのあな店舗(一部店舗除く)・通信販売"
          desc="全巻購入特典：アニメ描き下ろしB2タペストリー"
          imgList={[<Images name="toranoana" />]}
        />
        <Special
          title="ソフマップ・アニメガ"
          desc="全巻購入特典：アニメ描き下ろしB1タペストリー"
          imgList={[<Images name="sofmap" />]}
        />
        <Special
          title="あみあみ"
          desc="全巻購入特典：アクリルクロック"
          imgList={[<Images name="amiami" />]}
        />
        <Special
          title="楽天ブックス"
          desc="全巻購入特典：キャラファインボード"
          imgList={[<Images name="rakuten" />]}
        />
        <Special
          title="アマゾン"
          desc="全巻購入特典：B2布ポスター"
          imgList={[<Images name="amazon" />]}
        />
        <Special
          title="コロムビアミュージックショップ"
          desc="各巻購入特典：A4クリアファイル"
          imgList={[<Images name="cms02" />]}
        />
      </>
    ),
  },
]
const cItem = [
  {
    name: "OP",
    outline: (
      <>
        <div>
          <OutlineProduct accent>初回限定盤【CD+DVD】</OutlineProduct>
          <OutlineProduct>品番：COZC-1728～9</OutlineProduct>
          <OutlineProduct>価格：1,980円（税込）</OutlineProduct>
        </div>
        <br />
        <div>
          <OutlineProduct accent>通常盤【CD】</OutlineProduct>
          <OutlineProduct>品番：COCC-17860</OutlineProduct>
          <OutlineProduct>価格：1,320円（税込）</OutlineProduct>
        </div>
      </>
    ),
    detail: (
      <>
        <DetailH1>
          TVアニメ『スライム倒して300年、
          <Br />
          知らないうちにレベルMAXになってました』
          <br />
          オープニングテーマ
          <br />
          「ぐだふわエブリデー」初回限定盤
        </DetailH1>
        <Images name="op01" />
        <DetailH2>収録曲</DetailH2>
        <DetailOl>
          <DetailLi>
            ぐだふわエブリデー
            <br />　 作詞:OSTER project 作曲・編曲:涼木シンジ
          </DetailLi>
          <DetailLi>
            異世界管理局創造課
            <br />　 作詞:悠木碧　作曲・編曲:フワリ
          </DetailLi>
          <DetailLi>ぐだふわエブリデー（Instrumental）</DetailLi>
          <DetailLi>異世界管理局創造課（Instrumental）</DetailLi>
        </DetailOl>
        <DetailH2>DVD収録内容</DetailH2>
        <DetailOl>
          <DetailLi>ぐだふわエブリデー　Music Video</DetailLi>
          <DetailLi>悠木碧 Comment Movie</DetailLi>
        </DetailOl>
        <DetailH1>
          TVアニメ『スライム倒して300年、
          <Br />
          知らないうちにレベルMAXになってました』
          <br />
          オープニングテーマ
          <br />
          「ぐだふわエブリデー」通常盤
        </DetailH1>
        <Images name="op02" />
        <DetailH2>収録曲</DetailH2>
        <DetailOl>
          <DetailLi>
            ぐだふわエブリデー
            <br />　 作詞:OSTER project 作曲・編曲:涼木シンジ
          </DetailLi>
          <DetailLi>
            異世界管理局創造課
            <br />　 作詞:悠木碧　作曲・編曲:フワリ
          </DetailLi>
          <DetailLi>ぐだふわエブリデー（Instrumental）</DetailLi>
          <DetailLi>異世界管理局創造課（Instrumental）</DetailLi>
        </DetailOl>

        {/* <Youtube thumbnail="" url="" />
        <DetailH2>店舗別特典特典</DetailH2>
        <Special
          title="全国アニメイト"
          desc="BD全巻購入：全巻収納BOX"
          imgList={[
            <Images name="logo" />,
            <Images name="logo" />,
          ]}
        />
        <Special
          title="全国アニメイト"
          desc="BD全巻購入：全巻収納BOX"
          imgList={[
            <Images name="logo" />,
            <Images name="logo" />,
          ]}
        /> */}
      </>
    ),
  },
  {
    name: "ED",
    outline: (
      <>
        <div>
          <OutlineProduct accent>初回限定盤【CD+DVD】</OutlineProduct>
          <OutlineProduct>品番：COZC-1751-2</OutlineProduct>
          <OutlineProduct>価格：2,200円（税込）</OutlineProduct>
        </div>
        <br />
        <div>
          <OutlineProduct accent>通常盤【CD】</OutlineProduct>
          <OutlineProduct>品番：COCC-17881</OutlineProduct>
          <OutlineProduct>価格：1,650円（税込）</OutlineProduct>
        </div>
      </>
    ),
    detail: (
      <>
        <DetailH1>
          TVアニメ『スライム倒して300年、
          <Br />
          知らないうちにレベルMAXになってました』
          <br />
          エンディングテーマ
          <br />
          「Viewtiful Days!／記憶に恋をした」初回限定盤
        </DetailH1>
        <Images name="ed01" />
        <DetailH2>収録曲</DetailH2>
        <DetailOl>
          <DetailLi>
            Viewtiful Days!
            <br />　 作詞:坂井竜二 作曲・編曲:持田裕輔
          </DetailLi>
          <DetailLi>
            2030
            <br />　 作詞・作曲・編曲:ARAKI
          </DetailLi>
          <DetailLi>
            記憶に恋をした
            <br />　 作詞・作曲・編曲:鶴崎輝一
          </DetailLi>
          <DetailLi>
            hopeless
            <br />　 作詞:金子麻友美 作曲・編曲:和音
          </DetailLi>
          <DetailLi>Viewtiful Days!（Instrumental）</DetailLi>
          <DetailLi>2030（Instrumental）</DetailLi>
          <DetailLi>記憶に恋をした（Instrumental）</DetailLi>
          <DetailLi>hopeless（Instrumental）</DetailLi>
        </DetailOl>
        <DetailH2>DVD収録内容</DetailH2>
        <DetailOl>
          <DetailLi>「Viewtiful Days!」ミュージックビデオ+メイキング</DetailLi>
        </DetailOl>
        <DetailH1>
          TVアニメ『スライム倒して300年、
          <Br />
          知らないうちにレベルMAXになってました』
          <br />
          エンディングテーマ
          <br />
          「Viewtiful Days!／記憶に恋をした」通常盤
        </DetailH1>
        <Images name="ed02" />
        <DetailH2>収録曲</DetailH2>
        <DetailOl>
          <DetailLi>
            Viewtiful Days!
            <br />　 作詞:坂井竜二 作曲・編曲:持田裕輔
          </DetailLi>
          <DetailLi>
            2030
            <br />　 作詞・作曲・編曲:ARAKI
          </DetailLi>
          <DetailLi>
            記憶に恋をした
            <br />　 作詞・作曲・編曲:鶴崎輝一
          </DetailLi>
          <DetailLi>
            hopeless
            <br />　 作詞:金子麻友美 作曲・編曲:和音
          </DetailLi>
          <DetailLi>Viewtiful Days!（Instrumental）</DetailLi>
          <DetailLi>2030（Instrumental）</DetailLi>
          <DetailLi>記憶に恋をした（Instrumental）</DetailLi>
          <DetailLi>hopeless（Instrumental）</DetailLi>
        </DetailOl>
      </>
    ),
  },
]

/////////////////////////////////////////////////////////////////////////////////

const BlurayCD = () => {
  const [item, setItem] = useState("bluray")
  const [blurayItem, setBlurayItem] = useState(0)
  const [selectImage, setSelectImage] = useState(0)
  const [cdItem, setCdItem] = useState(0)

  const opacity = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0 },
    opacity: 1,
    reset: true,
  })

  return (
    <>
      <SEO title="Blu-ray/CD" />
      <h1>Blu-ray / CD</h1>
      <h2>ブルーレイ / CD</h2>
      <Nav>
        <NavButton
          selected={item === "bluray"}
          onClick={() => setItem("bluray")}
        >
          Blu-ray
        </NavButton>
        <NavButton selected={item === "cd"} onClick={() => setItem("cd")}>
          CD
        </NavButton>
      </Nav>
      <animated.div style={opacity}>
        {item === "bluray" && (
          <Content>
            <Outline>
              <OutlineSubTitle>
                スライム倒して300年、
                <br />
                知らないうちに
                <br />
                レベルMAXになってました
              </OutlineSubTitle>
              <OutlineTitle>Blu-ray</OutlineTitle>
              <OutlineNav>
                <OutlineNavButton
                  selected={blurayItem === 0}
                  onClick={() => setBlurayItem(0)}
                >
                  上巻
                </OutlineNavButton>
                <OutlineNavButton
                  selected={blurayItem === 1}
                  onClick={() => setBlurayItem(1)}
                >
                  下巻
                </OutlineNavButton>
              </OutlineNav>
              <div>{bItem[blurayItem].outline}</div>
            </Outline>
            <Detail>{bItem[blurayItem].detail}</Detail>
          </Content>
        )}
        {item === "cd" && (
          <Content>
            <Outline>
              <OutlineSubTitle>
                スライム倒して300年、
                <br />
                知らないうちに
                <br />
                レベルMAXになってました
              </OutlineSubTitle>
              <OutlineTitle>CD</OutlineTitle>
              <OutlineNav>
                <OutlineNavButton
                  selected={cdItem === 0}
                  onClick={() => setCdItem(0)}
                >
                  OP
                </OutlineNavButton>
                <OutlineNavButton
                  selected={cdItem === 1}
                  onClick={() => setCdItem(1)}
                >
                  ED
                </OutlineNavButton>
              </OutlineNav>
              <div>{cItem[cdItem].outline}</div>
            </Outline>
            <Detail>{cItem[cdItem].detail}</Detail>
          </Content>
        )}
      </animated.div>
    </>
  )
}
export default BlurayCD
