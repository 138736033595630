import React, { useState, useRef, useContext } from "react"
import styled, { createGlobalStyle } from "styled-components"
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
} from "react-spring/web"
import { Link } from "gatsby"
import Image from "./image"
import Footer from "./footer"
import { ScrollAPI } from "./layout"

export const data = [
  { name: "/", text: "TOP" },
  { name: "/onair", text: "ON AIR" },
  { name: "/news/", text: "NEWS" },
  { name: "/story/", text: "STORY" },
  { name: "/cast-staff/", text: "CAST/STAFF" },
  { name: "/character/", text: "CHARACTER" },
  { name: "/movie/", text: "MOVIE" },
  { name: "/radio/", text: "RADIO" },
  { name: "/books/", text: "BOOKS" },
  { name: "/bluray-cd", text: "Blu-ray/CD" },
  { name: "/goods", text: "GOODS" },
  { name: "/special", text: "SPECIAL" },
]

const MenuText = styled.p`
  font-family: "Amiri", sans-serif;
  letter-spacing: 0.15em;
  text-align: center;
  margin-right: -0.15em;
`
const Hamburger = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 3.2vw;
  margin-bottom: 1em;
  width: 10.66vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 1.1vw;
    width: 3.54vw;
    min-height: 14px;
    min-width: 44px;
  }
  span {
    background-color: #bcc2c7;
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    @media ${(props) => props.theme.breakpoints.pc} {
      width: 3.06vw;
    }
  }
`
const MenuList = styled(animated.div)`
  background-color: #fff;
  background-image: url(${require("../images/layout/background.svg")});
  background-repeat: repeat;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding-top: 5vh;
  z-index: 97;
`
const ItemWrap = styled(animated.div)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${`repeat(${Math.ceil(data.length / 2)}, 1fr)`};
  gap: 2vh 0;
  align-items: center;
  height: auto;
  text-align: center;
  padding: 0 ${(props) => props.theme.padding.sp};
  max-height: 60vh;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: ${`repeat(${Math.ceil(data.length / 3)}, 1fr)`};
    gap: 5vh 0;
    padding: 10vh ${(props) => props.theme.padding.pc};
  }
  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: ${`repeat(${Math.ceil(data.length / 4)}, 1fr)`};
  }
`
const Item = styled(animated.div)`
  height: 100%;
  position: relative;
  will-change: transform, opacity;
`
const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
const H1 = styled.h1`
  font-size: 2.18rem;
  line-height: 8vh;
  margin: 0 auto;
`
const H2 = styled.h2`
  font-size: 1.45rem;
  font-family: "Amiri", sans-serif;
  margin: auto;
  position: relative;
  line-height: 3em;
  width: 100%;
  ::before,
  ::after {
    content: "";
    height: 100%;
    width: 0%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out opacity, 0.8s ease-in-out width;
    z-index: -1;
  }
  ::before {
    border-bottom: 1px solid ${(props) => props.theme.colors.light};
    opacity: 0.5;
  }
  ::after {
    background-color: ${(props) => props.theme.colors.main};
    width: 100%;
    opacity: 0;
  }
  :hover {
    ::before {
      width: 100%;
    }
    ::after {
      opacity: 0.1;
    }
  }
`
const StyledFooter = styled(Footer)`
  /** RESET INDEX PAGE */
  /*
  margin-top: auto !important;
  flex-grow: initial;
  position: static;
  bottom: auto;
  */
  background-color: ${(props) => props.theme.colors.light} !important;
  position: absolute;
  bottom: 0;
  max-height: 20vh;
  overflow: hidden;
`

const hamburgerConfig = { duration: 200 }
const Menu = () => {
  const [viewMenu, setViewMenu] = useState(false)
  const [menuText, setMenuText] = useState("MENU")
  const scrollbar = useContext(ScrollAPI)

  const toggleMenu = (open: boolean) => {
    setViewMenu(open)
    setMenuText(viewMenu ? "MENU" : "CLOSE")
    if (scrollbar?.offset) {
      const element = document.querySelector(".scroll-content") as HTMLElement
      if (open) {
        element.classList.add("scroll-content--stop")
      } else {
        element.classList.remove("scroll-content--stop")
      }
    }

    const element = document.querySelector("body") as HTMLElement
    const frame = document.querySelector("#frame") as HTMLElement
    if (open) {
      element.classList.add("menu--open")
      frame.style.height = "100%"
    } else {
      element.classList.remove("menu--open")
      frame.style.height = "100vh"
    }
  }

  const springRef: any = useRef()
  const transRef: any = useRef()
  const transitions = useTransition(viewMenu ? data : [], (item) => item.name, {
    // ref: transRef,
    unique: true,
    trail: 600 / data.length,
    from: { opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0)" },
  })
  // useChain(viewMenu ? [springRef, transRef] : [transRef, springRef], [
  //   0,
  //   viewMenu ? 0.1 : 0.6,
  // ])

  const menuTop = useSpring({
    config: hamburgerConfig,
    from: {
      backgroundColor: "#000",
      height: "2px",
      top: "0%",
    },
    to: async (next: any) => {
      if (viewMenu) {
        await next({
          height: "1px",
          top: "50%",
        })
        await next({
          transform: "rotate(-20deg)",
        })
      } else {
        await next({
          transform: "rotate(0deg)",
        })
        await next({
          height: "2px",
          top: "0%",
        })
      }
    },
  })
  const menuMiddle = useSpring({
    config: hamburgerConfig,
    from: {
      height: "1px",
      top: "50%",
    },
    height: viewMenu ? "0px" : "1px",
  })
  const menuBottom = useSpring({
    config: hamburgerConfig,
    from: {
      top: "100%",
    },
    to: async (next: any) => {
      if (viewMenu) {
        await next({
          backgroundColor: "#000",
          top: "50%",
        })
        await next({
          transform: "rotate(20deg)",
        })
      } else {
        await next({
          transform: "rotate(0deg)",
        })
        await next({
          backgroundColor: "#bcc2c7",
          top: "100%",
        })
      }
    },
  })
  const menuList = useSpring({
    config: { duration: 600 },
    from: {
      pointerEvents: "none",
    },
    opacity: viewMenu ? "1" : "0",
    pointerEvents: viewMenu ? "auto" : "none",
  })

  return (
    <div>
      <button
        onClick={() => toggleMenu(!viewMenu)}
        style={{ position: "relative", zIndex: 98 }}
      >
        <Hamburger>
          <animated.span style={{ ...menuTop }} />
          <animated.span style={{ ...menuMiddle }} />
          <animated.span style={{ ...menuBottom }} />
        </Hamburger>
        <MenuText>{menuText}</MenuText>
      </button>
      <MenuList style={{ ...menuList }}>
        <H1>MENU</H1>
        <ItemWrap>
          {transitions.map(({ key, props, item }) => {
            return (
              <Item
                key={key}
                style={{ ...props }}
                onClick={() => toggleMenu(!viewMenu)}
              >
                <StyledLink to={item.name}>
                  <H2>{item.text}</H2>
                </StyledLink>
              </Item>
            )
          })}
        </ItemWrap>
        <StyledFooter />
      </MenuList>
    </div>
  )
}
export default Menu
