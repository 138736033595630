import React from "react"
import styled from "styled-components"

const Wrap = styled.footer`
  background-color: ${(props) => props.theme.colors.light};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  letter-spacing: 0.2em;
  margin-top: auto;
  padding: 6.67vw 0;
  width: 100vw;
  text-align: center;
  @media ${(props) => props.theme.breakpoints.pc} {
    padding: 5.19vh 0 4.93vh 0;
  }
`
const Twitter = styled.div`
  font-family: "Amiri", sans-serif;
  margin-bottom: 3.33vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    line-height: 1em;
    margin-bottom: 2.15vh;
    a {
      display: flex;
      align-items: center;
    }
    p {
      font-size: 1.29rem;
    }
    img {
      margin-left: 0.56vw;
      width: 1.25vw;
    }
  }
`
const Copyrights = styled.p`
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 0.71rem;
  }
`

type Props = {
  className?: string
}
const Footer = ({ className }: Props) => {
  return (
    <Wrap className={className}>
      <Twitter>
        <a
          href="https://twitter.com/slime300_PR"
          target="_blank"
          style={{ color: "#fff" }}
          rel="noopener noreferrer"
        >
          <p>OFFICIAL</p>
          <img src={require("../images/shared/twitter.svg")} alt="twitter" />
        </a>
      </Twitter>
      <Copyrights>Ⓒ森田季節・SBクリエイティブ／高原の魔女の家</Copyrights>
    </Wrap>
  )
}

export default Footer
