import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Image from "../components/image"
import { Link } from "gatsby"
import { fadeAnime } from "../components/animation"
import { animated } from "react-spring"
import { ContentfulSpecial } from "../../types/graphqlTypes"

const Tag = styled.p`
  background-color: ${(props) => props.theme.colors.accent};
  color: #fff;
  display: inline-block;
  font-family: "Amiri", sans-serif;
  padding: 0.1em 0.8em;
  transform: scale(0.82);
  transform-origin: left bottom;
`
const Date = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-size: 1.09rem;
  margin: 0.7em 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`
const H3 = styled.h3`
  color: ${(props) => props.theme.colors.font};
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.89rem;
  font-weight: 500;
  line-height: 1.35em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.75rem;
  }
`
const ImageWrap = styled.div`
  margin: 3em 0;
`
const Thumbnail = styled.img`
  margin-left: -${(props) => props.theme.padding.sp};
  width: 100vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-left: 0;
    width: 100%;
  }
`
const Section = styled.div`
  background-color: #fff;
  border: solid 1px ${(props) => props.theme.colors.light};
  margin: 0 -${(props) => props.theme.padding.sp};
  padding: 5.33vw ${(props) => props.theme.padding.sp};
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 4vw auto 8vw auto;
    padding: 5.55vw 12vw;
  }
`
const StyledLink = styled(Link)`
  display: block;
  margin: 16vw 0 12vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 8vw 0 3vw 0;
  }
`
const BackText = styled.p`
  border-bottom: solid 2px ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.accent};
  font-family: "Amiri", sans-serif;
  font-size: 1.64rem;
  letter-spacing: 0.18em;
  line-height: 1em;
  padding: 0.667em 0;
  text-align: center;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
    padding: 1.15em 0;
  }
`
const Value = styled.div`
  h1 {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 1.45rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: ${(props) => props.theme.colors.font};
    margin: 2.5em 0;
    text-align: left;
    line-height: 1.3em;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 1.71rem;
      margin: 1.58em 0;
    }
  }
  h2 {
    font-size: 1.09rem;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 400;
    margin: 2.5em 0;
    text-align: left;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 1.14rem;
      margin: 2.31em 0;
    }
  }
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1em;
    margin: 1em 0;
  }
  a {
    color: ${(props) => props.theme.colors.main};
    text-decoration: underline;
  }
  p {
    line-height: 1.64em;
  }
  hr {
    border: none;
    border-bottom: solid 1px #60bfeb;
    margin: 4.5em 0;
  }
  strong {
    font-weight: 400;
  }
  td {
    :first-child {
      &::after {
        content: "：";
        display: inline-block;
      }
    }
  }
  th {
    font-weight: 400;
  }
  table,
  ul {
    margin: 1em 0;
  }
  .gatsby-resp-image-wrapper {
    margin: 0 auto 0 0 !important;
  }
`
type SpecialContext = {
  post: ContentfulSpecial
}
type Props = {
  pageContext: SpecialContext
}
const Special = ({ pageContext }: Props) => {
  const content = pageContext.post
  const props = fadeAnime(1000)
  return (
    <>
      <SEO title="SPECIAL" />
      <animated.div style={{ ...props }}>
        <h1>Special</h1>
        <h2>スペシャル</h2>
        <Section>
          <H3>{content.title}</H3>
          <ImageWrap>
            {content.thumbnail && (
              <Thumbnail src={content.thumbnail!.fluid!.src!} alt="thumbnail" />
            )}
            {!content.thumbnail && <Image name="logo" />}
          </ImageWrap>
          <Value
            dangerouslySetInnerHTML={{
              __html: content.value!.childMarkdownRemark!.html!,
            }}
          ></Value>
          <StyledLink to="/special/">
            <BackText>BACK TO SPECIAL</BackText>
          </StyledLink>
        </Section>
      </animated.div>
    </>
  )
}
export default Special
