import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Youtube from "../components/youtube"
import SEO from "../components/seo"

const data = [
  {
    name: "PV第1弾",
    url: "https://www.youtube.com/watch?v=1xd5e2-Qb5Q",
    thumbnail: "http://img.youtube.com/vi/1xd5e2-Qb5Q/0.jpg",
  },
  {
    name: "PV第2弾",
    url: "https://www.youtube.com/watch?v=04BFCHXpBq8",
    thumbnail: "http://img.youtube.com/vi/04BFCHXpBq8/0.jpg",
  },
  {
    name: "放送直前特番",
    url: "https://www.youtube.com/watch?v=UAtaYV6Psd4",
    thumbnail: "http://img.youtube.com/vi/UAtaYV6Psd4/0.jpg",
  },
  {
    name: "ノンテロップOP",
    url: "https://www.youtube.com/watch?v=n6q-99Q0BlI",
    thumbnail: "https://img.youtube.com/vi/n6q-99Q0BlI/0.jpg",
  },
  {
    name: "ノンテロップED",
    url: "https://www.youtube.com/watch?v=Mwi0Yach0EM",
    thumbnail: "https://img.youtube.com/vi/Mwi0Yach0EM/0.jpg",
  },
  {
    name: "スライム倒して300年、生放送で打ち上げちゃいました",
    url: "https://www.youtube.com/watch?v=jV5nWoPMWXs",
    thumbnail: "http://img.youtube.com/vi/jV5nWoPMWXs/0.jpg",
  },
]
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 6.4vw;
  margin-top: 5.33vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: 1fr 1fr;
    gap: 3.33vw 2.22vw;
  }
`

const Content = styled.div``
const P = styled.p`
  font-size: 0.91rem;
  text-align: center;
  margin-top: 1.2em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`
const Movie = () => {
  return (
    <>
      <SEO title="MOVIE" />
      <h1>MOVIE</h1>
      <h2>ムービー</h2>
      <Wrap>
        {data.map((movie, index) => {
          return (
            <Content key={index}>
              <Youtube
                key={index}
                url={movie.url}
                thumbnail={movie.thumbnail}
              />
              <P>{movie.name}</P>
            </Content>
          )
        })}
      </Wrap>
    </>
  )
}
export default Movie
