import React, { useEffect } from "react"
import styled from "styled-components"

const MenuWrap = styled.div`
  margin-left: -${(props) => props.theme.padding.sp};
  margin-bottom: 14.67vw;
  position: relative;
  width: 100vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-left: -${(props) => props.theme.padding.pc};
    margin-bottom: 5.56vw;
  }
  ::after {
    content: "";
    border-bottom: solid 1px ${(props) => props.theme.colors.light};
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
`
const Items = styled.div`
  display: flex;
  margin: auto;
  overflow-x: scroll;
  justify-content: left;
  width: fit-content;
  @media ${(props) => props.theme.breakpoints.pc} {
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: auto;
    width: auto;
  }
`
const Item = styled.button<{ selected?: boolean }>`
  border-bottom: solid 1px
    ${({ selected }) =>
      selected ? (props) => props.theme.colors.accent : "transparent"};
  color: ${({ selected }) =>
    selected
      ? (props) => props.theme.colors.accent
      : (props) => props.theme.colors.font};
  display: block;
  font-family: "Amiri", "Noto Serif JP", sans-serif;
  letter-spacing: 0.18em;
  margin: 0 1vw;
  padding: 0 0.5vw;
  min-width: fit-content;
`
const Content = styled.div``
const ViewMore = styled.button`
  border-bottom: solid 2px ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.accent};
  font-family: "Amiri", sans-serif;
  font-size: 1.64rem;
  letter-spacing: 0.18em;
  margin-top: 16vw;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43em;
    margin-top: 6.81vw;
  }
`

type Props = {
  nodes: any[]
  tag: string
  setTag: (tag: string) => void
  count: number
  setCount: (count: number) => void
  length: number
  children: any
  all?: boolean | undefined
}
const ListLayout = ({
  nodes,
  tag,
  setTag,
  count,
  setCount,
  length,
  children,
  all,
}: Props) => {
  useEffect(() => {
    if (!all) {
      setTag(nodes[0].tag)
    }
  }, [])

  //タグリストの生成
  const createTagList = (array: any) => {
    let tmpValues: string[] = []
    array.filter((e: any) => {
      if (tmpValues.indexOf(e.tag!) === -1) {
        tmpValues.push(e.tag!)
        return e
      }
    })
    const values = tmpValues
    return values
  }
  const tagList: string[] = createTagList(nodes)

  const selectTag = (tag: string) => {
    setTag(tag)
    setCount(8)
    window.scrollTo(0, 0)
  }
  return (
    <>
      <MenuWrap>
        <Items>
          {all && (
            <Item onClick={() => selectTag("")} selected={tag === ""}>
              ALL
            </Item>
          )}
          {tagList.map((element, index) => {
            return (
              <Item
                key={index}
                onClick={() => selectTag(element)}
                selected={tag === element}
              >
                {element}
              </Item>
            )
          })}
        </Items>
      </MenuWrap>
      <Content>{children}</Content>
      {length > count && (
        <ViewMore onClick={() => setCount(nodes.length)}>MORE</ViewMore>
      )}
    </>
  )
}
export default ListLayout
