import React, { useState } from "react"
import { ScrollAPI } from "../components/layout"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { GoodsListQuery } from "../../types/graphqlTypes"
import SEO from "../components/seo"
import Image from "../components/image"
import ListLayout from "../components/list-layout"
import { useTransition, useTrail, animated } from "react-spring"

const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4.67vw 3.2vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const ImgWrap = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  :before {
    content: "";
    display: block;
    padding-top: 75%;
  }
`
const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const LogoWrap = styled.div`
  height: 31.25%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`
const Logo = styled(Image)`
  height: 100%;
  opacity: 0.3;
`
const Name = styled.p`
  margin-top: 1em;
`

type Props = {
  data: GoodsListQuery
}
const Goods = ({ data }: Props) => {
  const trail = useTrail(data.allContentfulGoods.nodes.length, {
    config: { duration: 750 },
    from: { opacity: 0 },
    opacity: 1,
  })

  return (
    <>
      <SEO title="GOODS" />
      <h1>GOODS</h1>
      <h2>グッズ</h2>
      <ContentWrap>
        {trail.map(({ opacity }, index) => {
          return (
            <Link
              key={data.allContentfulGoods.nodes[index].contentful_id!}
              to={"/goods/" + data.allContentfulGoods.nodes[index].slug}
            >
              <animated.div style={{ opacity }}>
                <ImgWrap>
                  {data.allContentfulGoods.nodes[index].thumbnails ? (
                    <Thumbnail>
                      <img
                        alt={data.allContentfulGoods.nodes[index].name!}
                        src={
                          data.allContentfulGoods.nodes[index].thumbnails![0]!
                            .fluid!.src!
                        }
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Thumbnail>
                  ) : (
                    <Thumbnail>
                      <div
                        style={{
                          border: "solid 1px #b2e2f8",
                          height: "100%",
                        }}
                      >
                        <LogoWrap>
                          <Logo name="logo" />
                        </LogoWrap>
                      </div>
                    </Thumbnail>
                  )}
                </ImgWrap>
                <Name>{data.allContentfulGoods.nodes[index].name}</Name>
              </animated.div>
            </Link>
          )
        })}
      </ContentWrap>
    </>
  )
}
export default Goods

export const pageQuery = graphql`
  query GoodsList {
    allContentfulGoods(sort: { fields: date, order: DESC }) {
      nodes {
        contentful_id
        title
        name
        thumbnails {
          fluid {
            src
          }
        }
        slug
      }
    }
  }
`
