import React, { useContext } from "react"
import { ScrollAPI } from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes, createGlobalStyle } from "styled-components"
import Image from "../components/image"
import Youtube from "../components/youtube"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { animated } from "react-spring"
import { fadeAnime } from "../components/animation"
import { Link } from "gatsby"

const imageDataList = [
  {
    index: 1,
    name: "keyvisual03",
  },
  {
    index: 2,
    name: "keyvisual04",
  },
  {
    index: 3,
    name: "keyvisual05",
  },
  {
    index: 4,
    name: "keyvisual06",
  },
  {
    index: 5,
    name: "keyvisual01",
  },
  {
    index: 6,
    name: "keyvisual02",
  },
]

const GlobalStyle = createGlobalStyle`
  main{
    margin-bottom: -1px;
    padding-bottom: 0 !important;
  }
  footer {
    margin-top: 0 !important;
    flex-grow: 1;
  }
  @media screen and (min-width: 768px) {
    header {
      .gatsby-image-wrapper {
        display: none;
      }
    }
    footer {
      /* background-color: transparent !important; */
      padding: 5.19vh 0 4.93vh 0 !important;
      position: fixed;
      pointer-events:none;
      bottom: 0;
      width: 34.71vw !important;
      transition: all 0.6s ease-in-out;
      z-index: 99;
      a{
        pointer-events: auto;
      }
    }
  }
  .scroll-content {
    min-height: 100vh;
  }
  .menu--open{
    footer {
      width: 100vw !important;
    }
    #title__text{
      opacity: 1 !important;
    }
  }
  #title__text{
    opacity: 0;
    transition: all 1.0s ease-in-out;
  }
`

const Wrap = styled(animated.div)<{ length: number }>`
  margin-left: -${(props) => props.theme.padding.sp};
  width: 100vw;
  overflow-x: hidden;
  .slick-slide {
    div {
      line-height: 0;
    }
  }
  .slick-thumb {
    /* background-color: ${(props) => props.theme.colors.light}; */
    background: rgba(100, 207, 240, 0.1);
    display: grid !important;
    grid-template-columns: ${({ length }) => `repeat(${length}, 14.1333vw)`};
    gap: 2.67vw 1.33vw;
    align-items: center;
    justify-content: center;
    margin: -1px 0;
    padding: 2.8vw 0;
    .gatsby-image-wrapper {
      height: 100%;
      img {
        object-fit: cover !important;
      }
    }
    li {
      height: 100%;
    }
    @media ${(props) => props.theme.breakpoints.pc} {
      background-color: transparent;
      gap: 0.90278vw;
      grid-template-rows: ${({ length }) => `repeat(${length},  5.625vw)`};
      grid-template-columns: 5.625vw;
      align-self: center;
      justify-content: end;
      flex: 1;
    }
    .slick-active {
      position: relative;
      ::before {
        content: "";
        background-color: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
  }
`
const Grid = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    display: grid;
    /* height: 82vh; */
    /* max-height: 45vmax; */
    width: 91.32vw;
    margin: auto auto auto 5.694vw;
    grid-template-columns: 24.5139vw 62.2222vw;
    grid-template-rows: 20.8886vh 24vh 19.6203vh 18vh 1fr;
    gap: 0 4.515vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*overflow: hidden;*/
    z-index: 2;
  }
`
const Logo = styled(Image)`
  display: none;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: block;
    grid-row: 1;
    grid-column: 1;
    height: fit-content;
    width: 18.0556vw;
    /* margin: 7.3417vh auto auto auto; */
    margin: auto auto 0 auto;
    max-height: 100%;
  }
`
const Message = styled.div`
  display: flex;
  position: relative;
  margin: 0 4vw 6.4vw 4vw;
  overflow: hidden;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-column: 1;
    grid-row: 2;
    margin: 0;
    padding: 0;
  }
`
const MessageImgPC = styled.img`
  display: none;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: block;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
`
const MessageImgSP = styled.img`
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  }
`
const MessageContent = styled.h1`
  border-top: solid 1px #99d9f6;
  border-bottom: solid 1px #99d9f6;
  font-size: 4.8vw;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  letter-spacing: 0.08em;
  padding: 0.6em;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2.2rem;
    line-height: 1.2em;
    padding: 0.3em;
    margin: 0.5em auto auto auto;
  }
`
const Bold = styled.span`
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.2em;
    font-weight: 500;
  }
`
const Br = styled.br`
  display: none;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: block;
  }
`
const MainImage = styled.div`
  .gatsby-image-wrapper {
    img {
      transform: scale(1.005);
    }
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2;
    grid-row: 1/6;
    width: 100%;
    .slick-slider {
      display: flex;
      /* flex-direction: column; */
      .slick-list {
        height: 100%;
        width: 54.8611vw;
        .gatsby-image-wrapper {
          height: 100vh;
          img {
            object-fit: cover !important;
          }
        }
      }
    }
  }
`
const ImageThumb = styled.div`
  /* background-color: ${(props) => props.theme.colors.light}; */
  height: 14.1333vw;
  margin: auto;
  /* @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  } */
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 100%;
    width: 100%;
  }
`
const Dots = styled.span`
  display: none;
  /* @media ${(props) => props.theme.breakpoints.pc} {
    background-color: transparent;
    display: block;
    border-radius: 100%;
    border: solid 1px #fff;
    height: 0.83vw;
    min-height: 10px;
    width: 0.83vw;
    min-width: 10px;
  } */
`

const MovieWrap = styled.div`
  display: flex;
  margin-top: 2.73rem;
  padding: 0 8.53vw;
  position: relative;
  overflow: hidden;
  @media ${(props) => props.theme.breakpoints.pc} {
    background-color: transparent;
    grid-column: 1;
    grid-row: 3;
    margin: auto 0;
    padding: 0;
  }
`
const MovieText = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-family: "Amiri", sans-serif;
  font-size: 1.818rem;
  font-weight: 400;
  flex: 1;
  letter-spacing: 0.1em;
  padding: 0;
  text-align: center;
  position: relative;
  margin-right: auto;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.429rem;
  }
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background-color: ${(props) => props.theme.colors.light};
    height: 1px;
    width: 100%;
    opacity: 0.3;
    z-index: -1;
  }
  > span {
    display: block;
    text-align: left;
    position: relative;
    width: fit-content;
    ::before {
      content: "";
      display: block;
      background-color: ${(props) => props.theme.colors.light};
      height: 3px;
      width: 100%;
    }
  }
`
const MovieContent = styled(Youtube)`
  margin: 0 0 auto 5.6vw;
  width: 48vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-left: 1.11vw;
    width: 17.3611vw;
  }
`
const RadioWrap = styled(MovieWrap)`
  margin: 1.818rem auto 2.73rem auto;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-row: 4;
    margin: auto 0;
  }
`
const RadioText = styled(MovieText)``
// const RadioContent = styled.div`
//   background-color: #e0f5fc;
//   border: solid 1px ${(props) => props.theme.colors.main};
//   color: ${(props) => props.theme.colors.main};
//   font-size: 0.91rem;
//   font-weight: 400;
//   line-height: 1.6em;
//   height: fit-content;
//   margin: 0 0 auto auto;
//   position: relative;
//   text-align: center;
//   width: 60vw;
//   @media ${(props) => props.theme.breakpoints.pc} {
//     font-size: 0.86rem;
//     line-height: 1.33em;
//     width: 17.3611vw;
//     p {
//       padding: 1em 0;
//     }
//   }
//   ::after {
//     content: "VIEW PAGE";
//     background-color: #fff;
//     display: block;
//     font-family: "Amiri", sans-serif;
//     font-size: 0.71rem;
//     letter-spacing: 0.2em;
//     line-height: 2em;
//     width: 100%;
//   }
// `
const RadioThumbWrap = styled.a`
  height: fit-content;
  margin-left: 5.6vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-left: 1.11vw;
  }
`
const RadioThumb = styled.img`
  display: block;
  margin: 0 0 auto auto;
  width: 48vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    width: 17.3611vw;
  }
`
const PCBG = styled.div`
  /* @media ${(props) => props.theme.breakpoints.pc} {
    background-color: ${(props) => props.theme.colors.light};
    height: 50vh;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
  } */
`

const IndexPage = () => {
  const scrollbar = useContext(ScrollAPI)
  const props = fadeAnime(600)
  const sliderSettings = {
    customPaging: (i: number) => {
      return (
        <a key={i} style={{ cursor: "pointer" }}>
          <ImageThumb>
            <Image name={imageDataList[i].name} />
          </ImageThumb>
          {/* <Dots /> */}
        </a>
      )
    },
    // autoplay: true,
    arrows: false,
    dots: true,
    dotsClass: "slick-thumb",
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "ease-in-out",
    autoplaySpeed: 7000,
    beforeChange: () => {
      //scrollbar!.scrollTo(0, 0)
    },
  }
  return (
    <>
      <GlobalStyle />
      <Wrap length={imageDataList.length} style={{ ...props }}>
        <SEO />
        <Grid>
          <Logo name="logo" />
          <Message>
            <MessageImgPC
              src={require("../images/index/message-pc.svg")}
              alt="message"
            />
            <MessageImgSP
              src={require("../images/index/message-sp.svg")}
              alt="message"
            />
          </Message>
          <MainImage>
            <Slider {...sliderSettings}>
              {imageDataList.map((imageData, index) => {
                return <Image key={index} name={imageData.name} />
              })}
            </Slider>
          </MainImage>
          <MovieWrap>
            <MovieText>
              <span>MOVIE</span>
            </MovieText>
            <MovieContent
              thumbnail={require("../images/visual/pv2-thumb.png")}
              url="https://www.youtube.com/watch?v=04BFCHXpBq8"
            />
          </MovieWrap>
          <RadioWrap></RadioWrap>
        </Grid>
      </Wrap>
      {/* <PCBG /> */}
    </>
  )
}

export default IndexPage
