import React, { useState } from "react"
import styled from "styled-components"
import Lottie from "lottie-react"
import { useSpring, animated } from "react-spring"
import Modal from "react-modal"
import ReactPlayer from "react-player"
import Image from "./image"

const Wrap = styled(animated.div)`
  background-color: #fff;
  background-image: url(${require("../images/layout/background.svg")});
  background-repeat: repeat;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  transform: translate3d(0, 0, 2px);
`
const AnimeWrap = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  transform: scale(1.8);
  pointer-events: none;
  @media ${(props) => props.theme.breakpoints.pc} {
    transform: scale(0.8);
  }
`
const PlayerContent = styled(ReactPlayer)`
  background-color: #fff;
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const StyledModal = styled(Modal)`
  height: 90vh !important;
  position: fixed;
  width: 63.534689vh !important;
  max-height: 127.4894vw !important;
  max-width: 90%;
`
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 998,
  },
}
const Loader = () => {
  const [toggle, setToggle] = useState(false)
  const [isModal, setModal] = useState(true)
  const data = require("../images/loader.json")
  const fadeAnime = useSpring({
    config: { duration: 500 },
    from: { opacity: 1 },
    opacity: toggle ? 0 : 1,
    pointerEvents: toggle ? "none" : "auto",
  })
  return (
    <>
      <Wrap style={fadeAnime}>
        <AnimeWrap>
          <Lottie
            loop={false}
            autoPlay={false}
            animationData={data}
            onComplete={() => setToggle(true)}
            // eventListeners={[
            //   {
            //     eventName: "complete",
            //     callback: () => {
            //       setToggle(true)
            //     },
            //   },
            // ]}
          />
        </AnimeWrap>
      </Wrap>
      {/* <StyledModal
        isOpen={isModal}
        onRequestClose={() => setModal(!isModal)}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <Image name="promo" />
      </StyledModal> */}
    </>
  )
}

export default Loader
