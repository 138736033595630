import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"

const broadList = [
  {
    name: "AT-X",
    content: "4月10日より 毎週土曜21:00~",
    subContent: "リピート放送：毎週月曜28：30~／毎週土曜6：00~",
  },
  {
    name: "TOKYO MX・BS11",
    content: "4月10日より 毎週土曜22:00~",
  },
  {
    name: "サンテレビ",
    content: "4月10日より 毎週土曜22:30~",
  },
  {
    name: "KBS京都",
    content: "4月10日より 毎週土曜23:00~",
  },
  {
    name: "北海道テレビ",
    content: "4月12日より 毎週月曜25:20~",
  },
  {
    name: "長崎文化放送",
    content: "4月21日より 毎週水曜25:18~",
  },
  {
    name: "IBC岩手放送",
    content: "7月13日より 毎週火曜深夜1:28~",
    subContent: "※初回のみ7月13日（火）深夜1:43～深夜2:13",
  },
]
const streamList = [
  {
    name: "ABEMA",
    content: "4月10日より 毎週土曜22:00~",
    url: "https://abema.tv/channels/abema-anime/slots/C7jwChQ1y6csHH",
    subContent: "※地上波同時・独占先行！",
  },
  {
    name: "dアニメストア",
    content: "4月14日（水）24:00より順次配信",
    url: "https://anime.dmkt-sp.jp/animestore/",
  },
  {
    name: "Amazon Prime Video",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.amazon.co.jp/gp/video/storefront/",
  },
  {
    name: "U-NEXT",
    content: "4月14日（水）24:00より順次配信",
    url: "https://video.unext.jp/",
  },
  {
    name: "アニメ放題",
    content: "4月14日（水）24:00より順次配信",
    url: "https://animehodai.my.softbank.jp/",
  },
  {
    name: "GYAO!",
    content: "4月14日（水）24:00より順次配信",
    url: "https://gyao.yahoo.co.jp/",
  },
  {
    name: "FOD",
    content: "4月14日（水）24:00より順次配信",
    url: "https://fod.fujitv.co.jp/",
  },
  {
    name: "バンダイチャンネル",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.b-ch.com/",
  },
  {
    name: "Hulu",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.hulu.jp/",
  },
  {
    name: "JCOM：オンデマンド",
    content: "4月14日（水）24:00より順次配信",
    url: "https://linkvod.myjcom.jp/",
  },
  {
    name: "TELASA/au スマートパスプレミアム",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.telasa.jp/freemium",
  },
  {
    name: "milplus",
    content: "4月14日（水）24:00より順次配信",
    url: "https://front.milplus.jp/",
  },
  {
    name: "ひかりTV",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.hikaritv.net/",
  },
  {
    name: "TSUTAYATV",
    content: "4月14日（水）24:00より順次配信",
    url: "https://movie-tsutaya.tsite.jp/netdvd/vod/top.do?i=2&pT=0&sc_int=movie_vod_header_vodtop",
  },
  {
    name: "HAPPY!動画",
    content: "4月14日（水）24:00より順次配信",
    url: "https://a.happydouga.jp/web/pc/index.php",
  },
  {
    name: "ムービーフルplus",
    content: "4月14日（水）24:00より順次配信",
    url: "https://mfplus.jp/",
  },
  {
    name: "Googleplay",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.hikaritv.net/",
  },
  {
    name: "ニコニコ",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.nicovideo.jp/",
  },
  {
    name: "VideoMarket",
    content: "4月14日（水）24:00より順次配信",
    url: "https://www.videomarket.jp/",
  },
  {
    name: "ニコニコ生放送",
    content: "4月15日より 毎週木曜23:00〜",
    url: "https://www.nicovideo.jp/",
  },
]

const Wrap = styled.div`
  padding: 1.39vw 0;
`
const Message = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-size: 1.63rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  text-align: center;
  padding-bottom: 8.53vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2.29rem;
    padding-bottom: 3.26vw;
  }
  > span {
    font-size: 1.18rem;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 1.57rem;
    }
  }
`
const H3 = styled.h3`
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  padding-top: 3.2vw;
  position: relative;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2.08vw;
  }
  ::after {
    content: "";
    background-color: ${(props) => props.theme.colors.light};
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`
const Content = styled.div`
  padding: 7.33vw 0 15.9vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    padding: 3.19vw 0 6.94vw 0;
  }
  :last-child {
    padding-bottom: 0;
  }
`
const NameSet = styled.div`
  font-size: 1.18rem;
  position: relative;
  padding: 1.2em 0 0.92em 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
    font-size: 1.71rem;
    padding-bottom: 0;
  }
  > div {
    position: relative;
    :first-child {
      padding-top: 0;
      margin-bottom: 0.6em;
    }
    @media ${(props) => props.theme.breakpoints.pc} {
      padding-bottom: 1.21rem;
      :first-child {
        margin: 0 2.92vw 0 0;
        width: 17.78vw;
      }
      :last-child {
        flex: 1;
      }
      ::after {
        content: "";
        background-color: ${(props) => props.theme.colors.light};
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }
  ::after {
    content: "";
    background-color: ${(props) => props.theme.colors.light};
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    @media ${(props) => props.theme.breakpoints.pc} {
      height: 0;
    }
  }
  p {
    font-weight: 400;
  }
  a {
    color: ${(props) => props.theme.colors.accent};
    display: inline-block;
    font-weight: 500;
    text-decoration: underline;
  }
  span {
    font-size: 0.95em;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 0.75em;
    }
  }
`
const Br = styled.br`
  display: block;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  }
`

const Onair = () => {
  return (
    <>
      <SEO title="ON AIR" />
      <h1>ON AIR</h1>
      <h2>オンエア</h2>
      <Message>
        TOKYO MX・BS11・AT-X<span>ほかにて</span>
        <Br />4<span>月</span>10<span>日(土)より</span>放送開始！
      </Message>
      <Wrap>
        <H3>放送情報</H3>
        <Content>
          {broadList.map((element, index) => {
            return (
              <NameSet key={index}>
                <div>
                  <p style={{ fontWeight: 500 }}>{element.name}</p>
                </div>
                <div>
                  <p>{element.content}</p>
                  {element.subContent && (
                    <p>
                      <span>{element.subContent}</span>
                    </p>
                  )}
                </div>
              </NameSet>
            )
          })}
          <p style={{ marginTop: "1em" }}>
            ※放送時間は予告なく変更になる場合がございます。
          </p>
        </Content>
        <H3>配信情報</H3>
        <Content>
          {streamList.map((element, index) => {
            return (
              <NameSet key={index}>
                <div>
                  <a
                    href={element.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {element.name}
                  </a>
                </div>
                <div>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    {element.content}
                  </p>
                  {element.subContent && (
                    <p>
                      <span style={{ textDecoration: "underline" }}>
                        {element.subContent}
                      </span>
                    </p>
                  )}
                </div>
              </NameSet>
            )
          })}
          <p style={{ marginTop: "1em" }}>
            ※配信時間は予告なく変更になる場合がございます。
          </p>
        </Content>
      </Wrap>
    </>
  )
}
export default Onair
