import React, { useState } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import BooksImage from "../components/books-image"

const bookList = {
  novelsMain: [
    {
      name: "nm01",
      link: "https://www.amazon.co.jp/dp/4797390441",
    },
    {
      name: "nm02",
      link: "https://www.amazon.co.jp/dp/4797391766",
    },
    {
      name: "nm03",
      link: "https://www.amazon.co.jp/dp/4797392959",
    },
    {
      name: "nm04",
      link: "https://www.amazon.co.jp/dp/4797392967",
    },
    {
      name: "nm05",
      link: "https://www.amazon.co.jp/dp/4797392975",
    },
    {
      name: "nm06",
      link: "https://www.amazon.co.jp/dp/4797396172",
    },
    {
      name: "nm07",
      link: "https://www.amazon.co.jp/dp/4797396199",
    },
    {
      name: "nm08",
      link: "https://www.amazon.co.jp/dp/4797399139",
    },
    {
      name: "nm09",
      link: "https://www.amazon.co.jp/dp/4815600961",
    },
    {
      name: "nm10",
      link: "https://www.amazon.co.jp/dp/4815602743",
    },
    {
      name: "nm11",
      link: "https://www.amazon.co.jp/dp/4815602751",
    },
    {
      name: "nm12",
      link: "https://www.amazon.co.jp/dp/4815605599",
    },
    {
      name: "nm13",
      link: "https://www.amazon.co.jp/dp/4815607052",
    },
    {
      name: "nm14",
      link: "https://www.amazon.co.jp/dp/481560651X",
    },
    {
      name: "nm15",
      link: "https://www.amazon.co.jp/dp/4815607060",
    },
    {
      name: "nm16",
      link: "https://www.amazon.co.jp/dp/4815607087",
    },
    {
      name: "nm17",
      link: "https://www.amazon.co.jp/dp/4815610908",
    },
  ],
  novelsBeelze: [
    {
      name: "nb01",
      link: "https://www.amazon.co.jp/dp/481560116X",
    },
  ],
  comicsMain: [
    {
      name: "cm01",
      link: "https://www.amazon.co.jp/dp/4757555822",
    },
    {
      name: "cm02",
      link: "https://www.amazon.co.jp/dp/4757557515",
    },
    {
      name: "cm03",
      link: "https://www.amazon.co.jp/dp/4757559127",
    },
    {
      name: "cm04",
      link: "https://www.amazon.co.jp/dp/4757560850",
    },
    {
      name: "cm05",
      link: "https://www.amazon.co.jp/dp/4757562861",
    },
    {
      name: "cm06",
      link: "https://www.amazon.co.jp/dp/475756502X",
    },
    {
      name: "cm07",
      link: "https://www.amazon.co.jp/dp/4757567499",
    },
    {
      name: "cm08",
      link: "https://www.amazon.co.jp/dp/4757571224",
    },
    {
      name: "cm09",
      link: "https://www.amazon.co.jp/dp/4757573219",
    },
  ],
  comicsBeelze: [
    {
      name: "cb01",
      link: "https://www.amazon.co.jp/dp/475756287X",
    },
    {
      name: "cb02",
      link: "https://www.amazon.co.jp/dp/4757565038",
    },
    {
      name: "cb03",
      link: "https://www.amazon.co.jp/dp/4757567502",
    },
  ],
}

const Content = styled.div`
  margin-bottom: 18.53vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin-top: 6vw;
    margin-bottom: 4vw;
  }
  :last-child {
    margin-bottom: 0;
  }
`
const H3 = styled.h3`
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
  }
`
const Publisher = styled.p`
  color: ${(props) => props.theme.colors.accent};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.075em;
`
const BookTitle = styled.p`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.24em;
  margin: 0.8em 0 1.2em 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2.29rem;
    margin: 0.4em 0 0.6em 0;
  }
`
const BookSubTitle = styled.p`
  color: #99a0a4;
  font-size: 0.91rem;
  margin-top: 0.8em;
  line-height: 1.6em;
`
const TextWrap = styled.div`
  display: flex;
  margin: 1.5em 0 0.8em 0;
`
const TextChild = styled.div`
  width: auto;
`
const Title = styled.p`
  color: #99a0a4;
  font-size: 0.91rem;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1rem;
  }
`
const Value = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-size: 1.36rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  margin-right: 1.5em;
  margin-bottom: 1em;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
    margin-right: 2.5em;
  }
`

const PublisherLink = styled.a`
  background-color: rgba(178, 226, 248, 0.2);
  border: solid 1px #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12.8vw;
  margin-bottom: 9.6vw;
  padding: 0 1em;
  position: relative;
  width: fit-content;
  word-break: keep-all;
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 4.44vw;
    margin-bottom: 3.89vw;
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border: 1px solid #acb7be;
  }
`
const PublisherLinkText = styled.p`
  font-weight: 500;
  padding: 0 1em;
`
const ImageWrap = styled.div<{ row: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: ${({ row }) => `repeat(${Math.ceil(row / 3)},1fr)`};
  gap: 4.27vw 2.13vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: ${({ row }) => `repeat(${Math.ceil(row / 4)},1fr)`};
  }
`
const BooksPage = () => {
  const linkIcon = require("../images/shared/icon/link.svg")
  return (
    <>
      <SEO title="BOOKS" />
      <h1>BOOKS</h1>
      <h2>書籍</h2>
      <Content>
        <H3>NOVELS</H3>
        <BookTitle>
          <Publisher>GAノベル</Publisher>
          スライム倒して300年、
          <br />
          知らないうちにレベルMAXになってました
        </BookTitle>
        <TextWrap>
          <TextChild>
            <Title>著者</Title>
            <Value>森田季節</Value>
          </TextChild>
          <TextChild>
            <Title>イラスト</Title>
            <Value>紅緒</Value>
          </TextChild>
        </TextWrap>
        <PublisherLink
          href="https://ga.sbcr.jp/sp/slime/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ height: "40%" }}
            src={require("../images/shared/icon/slime.png")}
            alt="slime"
          />
          <PublisherLinkText>原作公式サイト</PublisherLinkText>
          <img
            style={{ height: "50%" }}
            src={require("../images/shared/icon/link.svg")}
            alt="link"
          />
        </PublisherLink>
        <ImageWrap row={bookList.novelsMain.length}>
          {bookList.novelsMain.map((element) => {
            return (
              <a key={element.name} href={element.link} target="_blank" rel="noopener noreferrer">
                <BooksImage name={element.name} />
              </a>
            )
          })}
        </ImageWrap>
      </Content>
      <Content>
        <H3>NOVELS</H3>
        <BookSubTitle>
          スライム倒して300年、知らないうちにレベルMAXになってましたスピンオフ
        </BookSubTitle>
        <BookTitle>
          <Publisher>GAノベル</Publisher>
          ヒラ役人やって1500年、
          <br />
          魔王の力で大臣にされちゃいました
        </BookTitle>
        <TextWrap>
          <TextChild>
            <Title>著者</Title>
            <Value>森田季節 </Value>
          </TextChild>
          <TextChild>
            <Title>イラスト</Title>
            <Value>紅緒</Value>
          </TextChild>
        </TextWrap>
        <PublisherLink
          href="https://ga.sbcr.jp/sp/slime/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ height: "40%" }}
            src={require("../images/shared/icon/slime.png")}
            alt="slime"
          />
          <PublisherLinkText>原作公式サイト</PublisherLinkText>
          <img
            style={{ height: "50%" }}
            src={require("../images/shared/icon/link.svg")}
            alt="link"
          />
        </PublisherLink>
        <ImageWrap row={bookList.novelsBeelze.length}>
          {bookList.novelsBeelze.map((element) => {
            return (
              <a key={element.name} href={element.link} target="_blank" rel="noopener noreferrer">
                <BooksImage name={element.name} />
              </a>
            )
          })}
        </ImageWrap>
      </Content>
      <Content>
        <H3>COMICS</H3>
        <BookTitle>
          <Publisher>ガンガンコミックスONLINE</Publisher>
          スライム倒して300年、
          <br />
          知らないうちにレベルMAXになってました
        </BookTitle>
        <TextWrap>
          <TextChild>
            <Title>原作</Title>
            <Value>森田季節</Value>
          </TextChild>
          <TextChild>
            <Title>漫画</Title>
            <Value>シバユウスケ</Value>
          </TextChild>
          <TextChild>
            <Title>キャラクター原案</Title>
            <Value>紅緒</Value>
          </TextChild>
        </TextWrap>
        <PublisherLink
          href="https://www.ganganonline.com/contents/slime/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ height: "40%" }}
            src={require("../images/shared/icon/slime.png")}
            alt="slime"
          />
          <PublisherLinkText>コミックス公式サイト</PublisherLinkText>
          <img
            style={{ height: "50%" }}
            src={require("../images/shared/icon/link.svg")}
            alt="link"
          />
        </PublisherLink>
        <ImageWrap row={bookList.comicsMain.length}>
          {bookList.comicsMain.map((element) => {
            return (
              <a key={element.name} href={element.link} target="_blank" rel="noopener noreferrer">
                <BooksImage name={element.name} />
              </a>
            )
          })}
        </ImageWrap>
      </Content>
      <Content>
        <H3>COMICS</H3>
        <BookSubTitle>
          スライム倒して300年、知らないうちにレベルMAXになってましたスピンオフ
        </BookSubTitle>
        <BookTitle>
          <Publisher>ガンガンコミックスONLINE</Publisher>
          ヒラ役人やって1500年、
          <br />
          魔王の力で大臣にされちゃいました
        </BookTitle>
        <TextWrap>
          <TextChild>
            <Title>原作</Title>
            <Value>森田季節</Value>
          </TextChild>
          <TextChild>
            <Title>漫画</Title>
            <Value>村上メイシ</Value>
          </TextChild>
          <TextChild>
            <Title>キャラクター原案</Title>
            <Value>紅緒</Value>
          </TextChild>
        </TextWrap>
        <PublisherLink
          href="https://www.ganganonline.com/contents/hirayakunin/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ height: "40%" }}
            src={require("../images/shared/icon/slime.png")}
            alt="slime"
          />
          <PublisherLinkText>コミックス公式サイト</PublisherLinkText>
          <img
            style={{ height: "50%" }}
            src={require("../images/shared/icon/link.svg")}
            alt="link"
          />
        </PublisherLink>
        <ImageWrap row={bookList.comicsBeelze.length}>
          {bookList.comicsBeelze.map((element) => {
            return (
              <a key={element.name} href={element.link} target="_blank" rel="noopener noreferrer">
                <BooksImage name={element.name} />
              </a>
            )
          })}
        </ImageWrap>
      </Content>
    </>
  )
}

export default BooksPage
