import React, { useState } from "react"
import { ScrollAPI } from "../components/layout"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { NewsListQuery } from "../../types/graphqlTypes"
import SEO from "../components/seo"
import Image from "../components/image"
import ListLayout from "../components/list-layout"
import { useTransition, useTrail, animated } from "react-spring"

const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4.67vw 3.2vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const Content = styled.div``
const ImgWrap = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  :before {
    content: "";
    display: block;
    padding-top: 75%;
  }
`
const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const LogoWrap = styled.div`
  height: 31.25%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`
const Logo = styled(Image)`
  height: 100%;
  opacity: 0.3;
`
const Tag = styled.p`
  background-color: ${(props) => props.theme.colors.accent};
  font-family: "Amiri", sans-serif;
  color: #fff;
  display: inline-block;
  font-size: 0.91em;
  letter-spacing: 0.18em;
  line-height: 1em;
  margin: 8.31% 0;
  padding: 2.56% 5.12%;
  @media ${(props) => props.theme.breakpoints.pc} {
    margin: 5.86% 0;
  }
`
const Date = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-size: 0.91em;
  line-height: 1em;
  margin-bottom: 2.56%;
`

type Props = {
  data: NewsListQuery
}
const NewsPage = ({ data }: Props) => {
  const [viewTag, setTag] = useState("")
  const [viewCount, setViewCount] = useState(8)
  let pageCount = 0
  const filterData = data.allContentfulNews.nodes.filter((node) => {
    if (viewTag === "") {
      return node.tag
    }
    return node.tag === viewTag
  })

  const trail = useTrail(filterData.length, {
    config: { duration: 750 },
    from: { opacity: 0 },
    opacity: 1,
    reset: viewCount === 8 ? true : false,
  })

  return (
    <>
      <SEO title="NEWS" />
      <h1>NEWS</h1>
      <h2>最新情報</h2>
      <ListLayout
        nodes={data.allContentfulNews.nodes}
        tag={viewTag}
        setTag={(tag: string) => setTag(tag)}
        count={viewCount}
        setCount={(count: number) => setViewCount(count)}
        length={filterData.length}
        all
      >
        <ContentWrap>
          {trail.map(({ opacity }, index) => {
            pageCount += 1
            if (pageCount > viewCount) {
              return
            }
            return (
              <Link
                key={filterData[index].contentful_id!}
                to={"/news/" + filterData[index].slug}
              >
                <animated.div style={{ opacity }}>
                  <ImgWrap>
                    {filterData[index].thumbnail ? (
                      <Thumbnail>
                        <img
                          alt={filterData[index].title!}
                          src={filterData[index].thumbnail!.fluid!.src!}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Thumbnail>
                    ) : (
                      <Thumbnail>
                        <div
                          style={{
                            border: "solid 1px #b2e2f8",
                            height: "100%",
                          }}
                        >
                          <LogoWrap>
                            <Logo name="logo" />
                          </LogoWrap>
                        </div>
                      </Thumbnail>
                    )}
                  </ImgWrap>
                  <Tag>{filterData[index].tag}</Tag>
                  <Date>{filterData[index].date}</Date>
                  <p>{filterData[index].title}</p>
                </animated.div>
              </Link>
            )
          })}
        </ContentWrap>
      </ListLayout>
    </>
  )
}
export default NewsPage

export const pageQuery = graphql`
  query NewsList {
    allContentfulNews(sort: { fields: date, order: DESC }) {
      nodes {
        contentful_id
        title
        thumbnail {
          fluid {
            src
          }
        }
        date(formatString: "YYYY.MM.DD")
        tag
        slug
      }
    }
  }
`
