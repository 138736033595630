import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { StoryListQuery } from "../../types/graphqlTypes"
import Slider from "react-slick"
import { animated, useSpring } from "react-spring"
import Youtube from "../components/youtube"

const Wrap = styled.div`
  .slick-slider {
    padding-bottom: 10.4vw;
    width: 100%;
    @media ${(props) => props.theme.breakpoints.pc} {
      padding-bottom: 4.03vw;
    }
    .slick-list {
      height: 100%;
      width: 100%;
      .gatsby-image-wrapper {
        height: 100vh;
        img {
          object-fit: cover !important;
        }
      }
    }
    .slick-thumb {
      display: flex !important;
      align-items: center;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 0.33vw 0;
      @media ${(props) => props.theme.breakpoints.pc} {
        padding: 0.1736vw 0;
      }
      > li {
        margin: 0.33vw 0;
        width: calc(20% - 0.66vw);
        img {
          display: block;
          width: 100%;
        }
        @media ${(props) => props.theme.breakpoints.pc} {
          margin: 0.1736vw 0;
          width: calc(20% - 0.3472vw);
        }
      }

      .slick-active {
        position: relative;
        ::before {
          content: "";
          background-color: #fff;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.5;
          height: 100%;
          width: 100%;
          z-index: 1;
        }
      }
    }
    .slick-slide {
      div {
        line-height: 0;
      }
    }
  }
`
const Nav = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  gap: 4.27vw 0;
  padding-bottom: 13.3vw;
  @media ${(props) => props.theme.breakpoints.pc} {
    grid-template-columns: repeat(8, 12.5%);
    padding-bottom: 6.18vw;
  }
`
const NavButton = styled.button<{ selected: boolean }>`
  display: block;
  border-bottom: solid 1px
    ${({ selected }) =>
      selected
        ? (props) => props.theme.colors.accent
        : (props) => props.theme.colors.light};
  color: ${({ selected }) =>
    selected
      ? (props) => props.theme.colors.accent
      : (props) => props.theme.colors.main};
  font-family: "Amiri", sans-serif;
  font-size: 1.18rem;
  height: 8vw;
  letter-spacing: 0.1em;
  margin: auto 3.2vw;
  overflow: hidden;
  position: relative;
  > span {
    display: inline-block;
    font-size: 1.91rem;
    line-height: 8vw;
  }
  ::before {
    content: "";
    background-color: ${({ selected }) =>
      selected ? (props) => props.theme.colors.accent : "transparent"};
    display: block;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.429rem;
    height: 3.61vw;
    margin: auto 1.25vw;
    /* padding: 0 1.944vw; */
    > span {
      font-size: 2.286rem;
      line-height: 3.61vw;
    }
  }
`
const IntroSpan = styled.span`
  font-size: 1.36rem !important;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 2rem !important;
  }
`
const IntroContent = styled.div`
  font-size: 1rem;
  text-align: center;
  line-height: 2.16em;
  position: relative;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.14rem;
  }
`
const EpisodeContent = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    display: flex;
  }
`
const ImageThumb = styled.div`
  /* background-color: ${(props) => props.theme.colors.light}; */
  margin: auto;
  /* @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  } */
  @media ${(props) => props.theme.breakpoints.pc} {
    height: 100%;
    width: 100%;
  }
`
const Outline = styled.div`
  @media ${(props) => props.theme.breakpoints.pc} {
    flex: 1;
    padding-top: 4.3vw;
  }
`
const No = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    font-size: 1.45rem;
    font-weight: 500;
    margin: 0 2.8vw;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 2.29rem;
      margin: 0 1.18vw;
    }
  }
  > img {
    width: 6vw;
    @media ${(props) => props.theme.breakpoints.pc} {
      width: 3.06vw;
    }
  }
`
const Title = styled.p`
  font-size: 1.11rem;
  text-align: center;
  padding: 0.5em 0 8vw 0;
  @media ${(props) => props.theme.breakpoints.pc} {
    font-size: 1.43rem;
  }
`
const Detail = styled.div`
  width: 100%;
  @media ${(props) => props.theme.breakpoints.pc} {
    width: 50vw;
  }
`
const Staff = styled.div`
  h2 {
    font-size: 1.45rem;
    text-align: center;
    margin: 10.4vw auto 3.6vw auto;
    @media ${(props) => props.theme.breakpoints.pc} {
      font-size: 1.29rem;
      text-align: left;
      margin: 2.71vw auto 1.32vw auto;
    }
    ::after {
      content: "";
      display: block;
      background-color: ${(props) => props.theme.colors.light};
      height: 1px;
      margin: 3.2vw auto 0 auto;
      width: 3.6vw;
      @media ${(props) => props.theme.breakpoints.pc} {
        margin-top: 0.76vw;
        margin-left: 0;
        width: 1.67vw;
      }
    }
  }
  div {
    text-align: center;
    @media ${(props) => props.theme.breakpoints.pc} {
      text-align: left;
    }
  }
`
const SPBr = styled.br`
  display: block;
  @media ${(props) => props.theme.breakpoints.pc} {
    display: none;
  }
`
const PCBr = styled.br`
  display: block;
  @media ${(props) => props.theme.breakpoints.sp} {
    display: none;
  }
`

type Props = {
  data: StoryListQuery
}
const Story = ({ data }: Props) => {
  const edges = data.allContentfulStory.edges
  const [item, setItem] = useState(edges.length)
  const sliderSettings = {
    customPaging: (i: number) => {
      return (
        <a key={i} style={{ cursor: "pointer" }}>
          <ImageThumb>
            {item !== 0 && edges[item - 1].node.images![i]! && (
              <img src={edges[item - 1].node.images![i]!.fluid!.src!} />
            )}
          </ImageThumb>
        </a>
      )
    },
    // autoplay: true,
    arrows: false,
    dots: true,
    dotsClass: "slick-thumb",
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "ease-in-out",
    autoplaySpeed: 7000,
  }
  const opacity = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0 },
    opacity: 1,
    reset: true,
  })
  return (
    <Wrap>
      <SEO title="STORY" />
      <h1>STORY</h1>
      <h2>ストーリー</h2>
      <Nav>
        <NavButton
          selected={item === 0}
          onClick={() => setItem(0)}
          style={{ gridColumn: "1/3" }}
        >
          <IntroSpan>INTRODUCTION</IntroSpan>
        </NavButton>
        {edges.map((edge, index) => {
          return (
            <NavButton
              key={index}
              selected={item === edge.node.no}
              onClick={() => setItem(edge.node.no!)}
            >
              第<span>{edge.node.no}</span>話
            </NavButton>
          )
        })}
      </Nav>
      <animated.div style={opacity}>
        {item === 0 && (
          <IntroContent>
            <p>ごく普通のOL・相沢梓は働き過ぎが原因で過労死し、</p>
            <p>不老不死の魔女アズサとして異世界に転生した。</p>
            <p>
              前世での反省から、
              <SPBr />
              辺境の高原でのんびりスローライフを始めた彼女。
            </p>
            <br />
            <p>
              スライムを倒して小銭を稼ぎ、
              <SPBr />
              魔女らしく薬を作って麓の村のお世話をする。
            </p>
            <p>
              あとはとくに何もしない。
              <SPBr />
              そんな暮らしを続けるうち彼女は
              <PCBr />
              「高原の魔女さま」と
              <SPBr />
              親しまれるようになっていた――。
            </p>
            <br />
            <p>ところが300年後。</p>
            <br />
            <p>
              スライムを倒し続けた経験値で、いつの間にやら
              <SPBr />
              レベル99＝世界最強と
              <PCBr />
              なってしまっていたアズサ。
            </p>
            <br />
            <p>その噂は広まり、腕に自信のある冒険者はもちろん、</p>
            <p>
              決闘を挑んでくるドラゴン娘や、
              <SPBr />
              アズサを母と呼ぶ謎のモンスター娘まで
            </p>
            <p>押し掛けて来るようになってしまい――！？</p>
          </IntroContent>
        )}
        {item !== 0 && (
          <EpisodeContent>
            <Outline>
              <No>
                <img
                  src={require("../images/shared/text-decoration.svg")}
                  alt="decoration"
                />
                <p>第{edges[item - 1].node.no}話</p>
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src={require("../images/shared/text-decoration.svg")}
                  alt="decoration-rev"
                />
              </No>
              <Title>{edges[item - 1].node.title}</Title>
            </Outline>
            <Detail>
              <div>
                <Slider {...sliderSettings}>
                  {edges[item - 1].node!.images!.map((image, index) => {
                    return <img key={index} src={image?.fluid?.src} />
                  })}
                </Slider>
                {/* {edges[item - 1].node!.images!.map((image, index) => {
                return (
                  <img key={index} src={image?.fluid?.src} />
                )
              })} */}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    edges[item - 1].node.outline?.childMarkdownRemark?.html!,
                }}
              ></div>
              <Staff>
                <h2>STAFF</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      edges[item - 1].node.staff?.childMarkdownRemark?.html!,
                  }}
                ></div>
              </Staff>
              {edges[item - 1].node.youtube && (
                <Staff>
                  <h2>次回予告</h2>
                  <Youtube
                    url={`https://www.youtube.com/watch?v=${
                      edges[item - 1].node.youtube
                    }`}
                    thumbnail={`https://img.youtube.com/vi/${
                      edges[item - 1].node.youtube
                    }/0.jpg`}
                  />
                </Staff>
              )}
            </Detail>
          </EpisodeContent>
        )}
      </animated.div>
    </Wrap>
  )
}
export default Story

export const pageQuery = graphql`
  query StoryList {
    allContentfulStory(sort: { fields: no, order: ASC }) {
      edges {
        node {
          no
          title
          images {
            fluid {
              src
            }
          }
          outline {
            childMarkdownRemark {
              html
            }
          }
          staff {
            childMarkdownRemark {
              html
            }
          }
          youtube
        }
      }
    }
  }
`
