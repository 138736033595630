import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

type Props = {
  name: string
  className?: string
  style?: object
}
const Image = (props: Props) => {
  const name = props.name
  const style = props.style
  const className = props.className

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "shared/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyvisual01: file(relativePath: { eq: "visual/keyvisual01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyvisual02: file(relativePath: { eq: "visual/keyvisual02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyvisual03: file(relativePath: { eq: "visual/keyvisual03.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyvisual04: file(relativePath: { eq: "visual/keyvisual04.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyvisual05: file(relativePath: { eq: "visual/keyvisual05.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyvisual06: file(relativePath: { eq: "visual/keyvisual06.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Img
      className={className}
      fluid={data[name].childImageSharp.fluid}
      style={style}
      placeholderStyle={{ display: "none" }}
      imgStyle={{ objectFit: "contain" }}
      fadeIn={false}
      loading="eager"
    />
  )
}

export default Image
